import React, { useState, useEffect } from "react";
import {requestTemplateInspect, requestTemplateInspectWithFile} from "../../../../Util/reqApi";
import {t} from "i18next";

const ReqInspection = (props) => {
    const { modal, setModal, inspectionData, setIsChanged, setModal02} = props;

    const [commentContent, setCommentContent] = useState('');
    const [attachment, setAttachment] = useState([]);

    const modalClose = (e) => {
        e.preventDefault()
        setModal(false)
        setModal02(false);
    }

    return (
        <div className="pop_set_p" style={modal ? {display:'block'} : {display:'none'}}>
            <div className="pop_section">
                <div className="parea">
                    <div className="page" style={{width:'900px'}}>
                        <div className="ppheader">
                            <div className="htit">{t('modal.reqInspection')}</div>
                            <div className="rclose" onClick={()=>{setModal(false)}}><img src={'/images/icon_pop_close.png'} alt="닫기"/></div>
                        </div>
                        <div className="p_in_section">
                            <div className="p_in_s_tit">관련 문의사항 (선택)</div>
                            <div style={{margin:'5px', fontSize:'12px', color:"#666"}}>(500자 이내로 입력해주세요.)</div>
                            <textarea className="p_in_s_info" 
                                    style={{border:'none', height: '100%', minHeight:'200px'}} 
                                    value={commentContent} 
                                    onChange={(e) => {
                                        setCommentContent(e.target.value)
                                    }}
                                    placeholder="검수 관련 문의사항을 자유롭게 입력해주세요."></textarea>
                        </div>
                        <div className="p_in_section" style={{marginBottom: '10px'}}>
                            <div className="p_in_s_tit">파일 추가(선택)</div>
                            <input type="file" multiple style={{width:'100%'}}
                                onClick={(e) => {
                                    setAttachment(null);
                                }}
                                onChange={(e) => {
                                    setAttachment(Array.from(e.target.files));
                                }}>
                            </input>
                            <div style={{margin:'5px', fontSize:'12px', color:"#666"}}>(파일당 png, jpg, jpeg, gif, pdf, hwp, doc, docx만 가능하며, 최대 10개까지 첨부할 수 있습니다.)</div>
                        </div>
                        <div className="button_area_center">
                                <button 
                                    className="btn_sm6_green" 
                                    style={{marginRight: '10px'}}
                                    onClick={(e)=> {
                                        e.preventDefault();
                                        if(attachment && Array.isArray(attachment) && attachment.length !== 0) {
                                            if(commentContent.replace('/\r\n|\r|\n| /', '') === ''){
                                                alert('파일 첨부 시 파일에 대한 문의사항 입력이 필수입니다. 파일과 함께 보내실 설명을 입력해주세요.');
                                            }else{
                                                const data = {
                                                    userId: inspectionData.userId,
                                                    senderKey: inspectionData.senderKey,
                                                    templtCode: inspectionData.templtCode,
                                                    senderKeyType: "S",
                                                    commentContent: commentContent
                                                }
                                                requestTemplateInspectWithFile(data, attachment).then(result => {
                                                    if(result.data.code === '200'){
                                                        alert('정상적으로 검수요청 되었습니다.');
                                                        modalClose(e);
                                                        setIsChanged(true);
                                                    }else{
                                                        alert('검수요청에 실패하였습니다. 관리자에게 문의해주세요.');
                                                    }
                                                })
                                            }
                                        }else{
                                            requestTemplateInspect({
                                                userId: inspectionData.userId,
                                                senderKey: inspectionData.senderKey,
                                                templtCode: inspectionData.templtCode,
                                                senderKeyType: "S",
                                                commentContent: commentContent,
                                            }).then(result=>{
                                                if(result.data.code === '200'){
                                                    alert('정상적으로 검수요청 되었습니다.');
                                                    modalClose(e);
                                                    setIsChanged(true);
                                                }else{
                                                    alert('검수요청에 실패하였습니다. 관리자에게 문의해주세요.');
                                                }
                                            })
                                        }
                                }}>검수요청</button>
                                <button className="btn_sm6_white" onClick={() => {setModal(false)}}>닫기</button>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReqInspection;