import React, { useEffect, useState } from 'react';
import Loading from '../../Util/Loading';
import { getApiDetail, getApplicationData, modifyServiceApplication, serviceApplication } from '../../Util/reqApi';

const ServiceConsoleTab_infor = (props) => {
    const {svcNo, svcData} = props;
    const [svcInfor, setSvcInfor] = useState();
    const [ip, setIp] = useState('');
    const [callbackUrl, setCallbackUrl] = useState('');
    const [optionData, setOptionData] = useState();
    const [slaveOptionNo, setSlaveOptionNo] = useState([]);
    const [ref, setRef] = useState(true);

    const [isLoding, setIsLoding] = useState(false);

    useEffect(()=>{
        setIsLoding(false);
        setSlaveOptionNo([]);
        setOptionData({})

        getApplicationData(svcNo).then(result => {
            if(svcData.slaveSvcList.length === 0){
                setIp(result.ip);
                setCallbackUrl(result.callbackUrl);
                setSvcInfor(result);
                if(result.applicationJson === null || result.applicationJson === '%5B' || result.applicationJson === '' || result.applicationJson === 'null'){
                    getApiDetail(svcNo).then(svcOption => {
                        if(svcOption.serviceApplicationForm === null || svcOption.serviceApplicationForm === ''){
                            setOptionData([]);
                            setIsLoding(true);
                        }else{
                            const originOption = JSON.parse(decodeURIComponent(svcOption.serviceApplicationForm));
                            setOptionData(originOption);
                            setIsLoding(true);
                        }
                    })
                    return;
                }
                result.applicationJson &&
                getApiDetail(svcNo).then(svcOption => {
                    if(svcOption.serviceApplicationForm && result.applicationJson){
                        const originOption = JSON.parse(decodeURIComponent(svcOption.serviceApplicationForm));
                        const applyOption = JSON.parse(decodeURIComponent(result.applicationJson));
                        const mergedOption = originOption.map(el => {
                            if(applyOption !== null){
                                if(applyOption.find(e => e.key === el.key)?.value !== undefined){
                                    el.value = applyOption.find(e => e.key === el.key).value
                                }
                            }
                            return el
                        })
                        setOptionData(mergedOption);
                        setIsLoding(true);
                    }
                })
            }
            if(svcData.slaveSvcList.length > 0){
                for(let i = 0; i < svcData.slaveSvcList.length; i++){
                    getApplicationData(svcData.slaveSvcList[i].svcNo).then(async result_app => {
                        if(result_app === "NO_DATA"){
                            getApiDetail(svcData.slaveSvcList[i].svcNo).then(noResult => {
                                setSlaveOptionNo(prev => [...prev, {
                                    svcNo: noResult.svcNo,
                                    name: noResult.nameKr,
                                    data: noResult.serviceApplicationForm && JSON.parse(decodeURIComponent(noResult.serviceApplicationForm)),
                                    noData: true,
                                }])
                                setIsLoding(true);
                                
                            })
                        }else if(result_app === "해당 서비스 신청정보는 승인 대기중입니다"){
                            getApiDetail(svcData.slaveSvcList[i].svcNo).then(noResult => {
                                setSlaveOptionNo(prev => [...prev, {
                                    svcNo: noResult.svcNo,
                                    name: noResult.nameKr,
                                    data: "해당 서비스 신청정보는 승인 대기중입니다.",
                                    waitApply: true,
                                }])
                                setIsLoding(true);
                            })
                        }else{  
                            await getApiDetail(svcData.slaveSvcList[i].svcNo).then(svcOption => {
                                if(svcOption.serviceApplicationForm){
                                    const originOption = JSON.parse(decodeURIComponent(svcOption.serviceApplicationForm));
                                    let applyOption = decodeURIComponent(result_app.applicationJson);
                                    try{
                                        applyOption = JSON.parse(applyOption)
                                    }catch{
                                        applyOption = null
                                    }
                                    const mergedOption = originOption.map(el => {
                                        if(applyOption !== null){
                                            if(applyOption.find(e => e.key === el.key)?.value !== undefined){
                                                el.value = applyOption.find(e => e.key === el.key).value
                                            }
                                        }
                                        return el
                                    })
                                    setSlaveOptionNo(prev => [...prev, {
                                        name: result_app.svcDetail.nameKr,
                                        ip: result_app.ip,
                                        callback: result_app.callbackUrl,
                                        svcNo: result_app.applicationNo,
                                        data: mergedOption
                                    }])
                                }else{
                                    setSlaveOptionNo(prev => [...prev, {
                                        name: result_app.svcDetail.nameKr,
                                        ip: result_app.ip,
                                        callback: result_app.callbackUrl,
                                        svcNo: result_app.applicationNo,
                                        data: []
                                    }])
                                }
                            })
                            setIsLoding(true);
                        }
                    })
                }
            }
        })

        return () => {
            setSvcInfor();
            setIp('');
            setCallbackUrl('');
            setOptionData([]);
            setSlaveOptionNo();
        }
    },[ref])

    const save_apply = (prdType, options) => {
        let isChk = window.confirm('아래 정보로 서비스 신청을 하시겠습니까?');
        if(isChk){
            serviceApplication(prdType, [{
                svcNo : options.svcNo,
                callback: options.callback,
                ip : options.ip,
                applicationJson: options.data || []
            }]).then(result => {
                if(result === 'ok'){
                    alert('정상적으로 신청되었습니다.');
                    setRef(!ref)
                }else{
                    alert(result.data.data);
                }
            })
        }
    }

    const modify_slave = (options) => {
        let isChk = window.confirm('아래 정보로 연동정보를 수정 하시겠습니까?');
        if(isChk){
            modifyServiceApplication({
                applicationNo : options.svcNo,
                callbackUrl: options.callback,
                ip : options.ip,
                applicationJson: options.data || []
            }).then(result => {
                if(result.data.data === 'ok'){
                    alert('정상적으로 변경되었습니다.');
                }else{
                    alert(result.data.data);
                }
            })
        }
    }

    const modify = () => {
        modifyServiceApplication({
            applicationNo : svcInfor.applicationNo,
            callbackUrl: callbackUrl,
            ip : ip,
            applicationJson: optionData || []
        }).then(result => {
            if(result.data.data === 'ok'){
                alert('정상적으로 변경되었습니다.');
            }else{
                alert(result.data.data);
            }
        })
    }

    if(isLoding){
        return(
            <div>
                <div className="tt_h2">연동 정보</div>
                <div className="tt_script">
                · 서비스 신청 시 입력한 연동 정보를 확인/수정 할 수 있습니다.
                </div>
                {
                    slaveOptionNo?.length === 0 &&
                    //슬레이브가 없으면 마스터 API 의 정보 노출
                    <>
                        <div className="tt_h2" style={{marginTop:"20px"}} dangerouslySetInnerHTML={{__html:svcInfor.masterSvc.nameKr}}></div>
                        <div className="option_bar5">
                            <div className="t_date_option_arT" temp={'bordertop'}>
                                <span className="op_box">
                                    <span style={{display:"inline-block", width:"100px"}}>IP</span>
                                    <input type="text" style={{width:"240px", marginRight:'10px'}} value={ip} onChange={(e)=>{setIp(e.target.value)}}/>
                                </span>
                            </div>
                            <div className="t_date_option_arT" temp={'bordertop'}>
                                <span className="op_box">
                                    <span style={{display:"inline-block", width:"100px"}}>CallBack URL</span>
                                    <input type="text" style={{width:"240px", marginRight:'10px'}} value={callbackUrl} onChange={(e)=>{setCallbackUrl(e.target.value)}}/>
                                </span>
                            </div>
                            {
                                optionData
                                ?
                                        optionData !== '' && optionData?.map((option,idx) => {
                                        if(option.user_editable === true || option.user_editable === "true"){
                                            return (
                                                <div className="t_date_option_arT" temp={'bordertop'} key={idx}>
                                                    <span className="op_box">
                                                        <span style={{display:"inline-block", width:"100px"}}>{option.key}</span>
                                                        <input
                                                            type="text"
                                                            style={{width:"240px", marginRight:'10px'}}
                                                            value={option.value || ""}
                                                            onChange={(e)=>{
                                                                setOptionData(
                                                                    prev => {
                                                                        prev[idx].value = e.target.value;
                                                                        return [...prev]
                                                                    }
                                                                )
                                                            }}
                                                            // disabled={option.user_editable ? false : true}
                                                        />
                                                    </span>
                                                </div>
                                            )
                                        }else{
                                            return null;
                                        }
                                    })
                                :   null
                            }
                            <button className="btn_sm6" style={{marginTop:'30px'}} onClick={modify}>연동정보 수정</button>
                        </div>
                    </>
                }
                {
                    slaveOptionNo
                    ?
                            slaveOptionNo.sort((a,b) => a.svcNo - b.svcNo )?.map((options,idx) => {
                                if(options.waitApply){
                                    return(
                                        <>
                                        <div key={idx}>
                                            <div className="tt_h2" style={{marginTop:"20px"}} dangerouslySetInnerHTML={{__html: options.name}}></div>
                                            <div className="option_bar5">
                                                <div className="t_date_option_arT" temp={'bordertop'}>
                                                    <span className="op_box">
                                                        <span style={{display:"inline-block"}}>{options.data}</span>
                                                    </span>
                                                </div>
                                                <button className="btn_sm6_yellow" style={{marginTop:'30px'}} >승인대기중</button>
                                            </div>
                                        </div>
                                        </>
                                    )
                                }
                                return(
                                    <div key={idx}>
                                        <div className="tt_h2" style={{marginTop:"20px"}} dangerouslySetInnerHTML={{__html: options.name}}></div>
                                        <div className="option_bar5">
                                            <div className="t_date_option_arT" temp={'bordertop'}>
                                                <span className="op_box">
                                                    <span style={{display:"inline-block", width:"100px"}}>IP</span>
                                                    <input type="text" style={{width:"240px", marginRight:'10px'}} value={options.ip} onChange={(e)=>{
                                                        setSlaveOptionNo(
                                                            prev => {
                                                                prev[idx].ip = e.target.value;
                                                                return [...prev]
                                                            }
                                                        )
                                                    }}/>
                                                </span>
                                            </div>
                                            <div className="t_date_option_arT" temp={'bordertop'}>
                                                <span className="op_box">
                                                    <span style={{display:"inline-block", width:"100px"}}>CallBack URL</span>
                                                    <input type="text" style={{width:"240px", marginRight:'10px'}} value={options.callback} onChange={(e)=>{
                                                        setSlaveOptionNo(
                                                            prev => {
                                                                prev[idx].callback = e.target.value;
                                                                return [...prev]
                                                            }
                                                        )
                                                    }}/>
                                                </span>
                                            </div>
                                            <SlaveOptionList data={{options, idx}} setSlaveOptionNo={setSlaveOptionNo} key={idx}/>
                                            {
                                                options.noData
                                                ? <button className="btn_sm6_red" style={{marginTop:'30px'}} onClick={()=>{save_apply(2, options)}}>사용신청</button>
                                                : <button className="btn_sm6" style={{marginTop:'30px'}} onClick={()=>{modify_slave(options)}}>연동정보 수정</button>
                                            }
                                        </div>
                                    </div>
                                )
                        })
                    :  null
                }
            </div>
        )
    }else return <Loading />
}

const SlaveOptionList = ({data, setSlaveOptionNo},) => {
    if(data.options.data !== ''){
        return(
            <>
                {
                    data?.options?.data?.map((option, idx) => {
                        if(option.user_editable === true || option.user_editable === "true"){
                            return (
                                <div className="t_date_option_arT" temp={'bordertop'} key={idx}>
                                    <span className="op_box">
                                        <span style={{display:"inline-block", width:"100px"}}>{option.key}</span>
                                        <input
                                            type="text"
                                            style={{width:"240px", marginRight:'10px'}}
                                            value={option.value}
                                            onChange={(e)=>{
                                                setSlaveOptionNo(prev => {
                                                    prev[data.idx].data[idx].value = e.target.value;
                                                    return [...prev];
                                                })
                                            }}
                                        />
                                    </span>
                                </div>
                            )
                        }else return null;
                    })
                }
            </>
        )
    }else return null
}

export default ServiceConsoleTab_infor;

