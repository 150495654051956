import axios from 'axios';
import { t } from 'i18next';
import { isNull } from 'lodash';

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

export const cancleReq = () => source.cancel();

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const {
            config,
            response: { status },
        } = error;
        const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
        const accToken = localStorage.getItem('ACCESS_TOKEN');
        const refToken = localStorage.getItem('REFRESH_TOKEN');
        if (status === 403) {
            const originalRequest = config;
            const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/user/refreshToken`, null, {
                header: { Authorization: auth },
                params: {
                    accessToken: accToken,
                    refreshToken: refToken,
                },
            });
            if (result.data.data == '잘못된 access token') {
                localStorage.removeItem('ACCESS_TOKEN');
                localStorage.removeItem('REFRESH_TOKEN');
                window.location.href = '/login';
                return;
            }
            localStorage.setItem('ACCESS_TOKEN', result.data.data.accessToken);
            localStorage.setItem('REFRESH_TOKEN', result.data.data.refreshToken);
            axios.defaults.headers.common.Authorization = `Bearer ${result.data.data.accessToken}`;
            originalRequest.headers.Authorization = `Bearer ${result.data.data.accessToken}`;
            return axios(originalRequest);
        }
        if (status === 500) {
            return 500;
        }
        return Promise.reject(error);
    }
);

export const getSiteCategory = async () => {
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/main/getSiteCategory`);
    return result.data.data;
};

export const getSiteCategoryDetail = async (cateNo) => {
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/main/getSiteCategoryDetail`, null, {
        params: {
            cateNo: cateNo,
        },
    });
    return result.data.data;
};

export const getMainBanner = async (bannerType, langNo) => {
    // langNo - 1:KR, 2:EN
    // bannerType - P : 프로모션 배너, S: 서브배너, E: 이벤트 배너

    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const {
        data: {
            data: { mainBannerList },
        },
    } = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/main/getMainBanner`, null, {
        headers: {
            Authorization: auth,
        },
        params: {
            bannerType: bannerType,
            langNo: langNo,
        },
    });
    if (!mainBannerList) {
        mainBannerList = [];
    }
    return mainBannerList;
};

export const getCurrentSvcTerm = async () => {
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/main/getTerm?langNo=${t('langNo')}&pageNumber=0&pageSize=9999&type=U`);
    return result.data.data.termList[0].content;
};

export const modifyServiceApplication = async (data) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;

    const formData = new FormData();

    let jsonData = '[';
    if (!data.applicationJson || data.applicationJson.length === 0) {
        jsonData = null;
    } else {
        for (let i = 0; i < data.applicationJson.length; i++) {
            if (i == data.applicationJson.length - 1) {
                jsonData += JSON.stringify(data.applicationJson[i]) + ']';
            } else {
                jsonData += JSON.stringify(data.applicationJson[i]) + ',';
            }
        }
        // jsonData = encodeURIComponent(jsonData)
    }

    formData.append(`applicationNo`, data.applicationNo);
    formData.append(`callbackUrl`, data.callbackUrl);
    formData.append(`ip`, data.ip);
    formData.append(`applicationJson`, jsonData);

    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/console/modifyServiceApplication`, formData, {
        headers: {
            Authorization: auth,
            'Content-type': `x-www-form-urlencoded`,
        },
    });
    // console.log(result);
    return result;
};

export const serviceApplication = async (type, applicationJson) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;

    if (type == 2) {
        const formData = new FormData();

        applicationJson.map((el, idx) => {
            let jsonData;
            if (el.data) {
                jsonData = '[';
                for (let i = 0; i < el.data.length; i++) {
                    if (i == el.data.length - 1) {
                        jsonData += JSON.stringify(el.data[i]) + ']';
                    } else {
                        jsonData += JSON.stringify(el.data[i]) + ',';
                    }
                }
                if (el.data.length === 0) {
                    jsonData = [];
                } else {
                    // jsonData = encodeURIComponent(jsonData)
                }
            } else {
                jsonData = [];
            }

            formData.append(`applicationList[${idx}].svcNo`, el.svcNo);
            formData.append(`applicationList[${idx}].callbackUrl`, applicationJson[0]?.callback);
            formData.append(`applicationList[${idx}].ip`, applicationJson[0]?.ip);
            formData.append(`applicationList[${idx}].applicationJson`, jsonData);
        });

        const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/console/serviceApplication`, formData, {
            headers: {
                Authorization: auth,
                'Content-type': `x-www-form-urlencoded`,
            },
        });

        return result.data.data;
    } else {
        const formData = new FormData();

        formData.append(`applicationList[0].svcNo`, applicationJson[0].svcNo);
        formData.append(`applicationList[0].callbackUrl`, applicationJson[0]?.callback);
        formData.append(`applicationList[0].ip`, applicationJson[0]?.ip);

        const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/console/serviceApplication`, formData, {
            headers: {
                Authorization: auth,
                'Content-type': `application/json`,
            },
        });

        return result.data.data;
    }
};

export const getApiCategorys = async () => {
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/svc/getSvcCategory`);
    return result.data.data;
};

export const getSvcMainCategory = async () => {
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/svc/getSvcMainCategory`);
    return result.data.data;
};

export const getApiItems = async (cateNo) => {
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/svc/getSvc?cateNo=${cateNo}&pageNumber=0&pageSize=9999`);
    return result.data.data;
};

export const chkMySvc = async (svcNo) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/console/checkMyService`, null, {
        headers: { Authorization: auth },
        params: { svcNo: svcNo },
    });
    return result.data.data;
};

export const getMySvc = async () => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/console/getMyService`, null, { headers: { Authorization: auth } });
    return result.data.data;
};

export const getApiDetail = async (svcNo) => {
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/svc/getSvcDetail?svcNo=${svcNo}`, null, {
        cancelToken: source.token,
    });

    return result.data.data;
};

export const getSearchResult = async (searchStr, lan) => {
    const result = await axios.get(process.env.REACT_APP_API_SERVER_URL+`/api/svc/getSearchResult?searchStr=${searchStr}&lan=${lan?lan:'ko'}`, null, {
        cancelToken: source.token,
    });
    return result.data.data;
};

export const getSubBn = async (lan) => {
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/main/getMainBanner?bannerType=S&langNo=${lan}`);
    return result.data.data;
};

export const getFooterData = async (lan) => {
    const result = await axios.get(process.env.REACT_APP_API_SERVER_URL+`/api/main/getSiteConfig?langNo=${lan}`);
    return result.data.data;
};

export const getReqCategory = async (langNo) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/board/getInquiryCategory`, null, {
        headers: {
            Authorization: auth,
        },
        params: {
            langNo: langNo,
        },
    });
    return result.data.data;
};

export const sendReq = async (querys, fileList) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const { reqType, reqSType, reqEmail, reqNumber, reqNumberAlarm, reqPwd, reqSubject, reqContent } = querys;
    
    let form = new FormData();
    
    if(fileList && fileList.length >= 0) {
        fileList.forEach((file) => {
            form.append('attachment', file);
        })
    }

    form.append('data', new Blob([JSON.stringify({
        svcType : reqSType,
        cateNo : reqType,
        content : reqContent,
        email : reqEmail,
        isNotiPhone : reqNumberAlarm,
        password : reqPwd,
        phone : reqNumber,
        subject : reqSubject
    })], { type: 'application/json' }));

    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/board/saveInquiry`, form, {
        headers: {
            Authorization: auth,
            'Content-Type': 'multipart/form-data'
        }
    });

    return result.data;
};

export const getPaymentLog = async (fromRequestedDatetime, toRequestedDatetime) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/myPage/getPaymentLog`, null, {
        params: {
            fromRequestedDatetime: fromRequestedDatetime,
            pageNum: 1,
            pageSize: 999,
            toRequestedDatetime: toRequestedDatetime,
        },
        headers: {
            Authorization: auth,
        },
    });
    if (result.data.data == 'NO_DATA') {
        return 'err';
    }
    return result.data.data;
};

export const getAccessTokenToLocal = () => {
    return localStorage.getItem('ACCESS_TOKEN');
};

export const getUserInfor = async () => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.get(process.env.REACT_APP_API_SERVER_URL+'/api/myPage/getUserInfo', {
        headers: {
            Authorization: auth,
        },
    });
    return result.data.data;
};

export const findMaster = async (userId) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/myPage/findMaster`, { userId: userId}, 
    {
        headers: {
            Authorization: auth,
        }
    });

    if (result == 500) {
        return null;
    }
    return result.data.data;
};

export const requestUserRelation = async (masterId) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
        const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/myPage/requestUserRelation`, {masterId: masterId}, {
        headers: {
            Authorization: auth,
            'Content-type': 'application/json',
        }
    });
    return result.data.data;
};

export const getUserRelation = async () => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/myPage/getUserRelation`, null, {
        headers: {
            Authorization: auth,
        },
    });
    return result.data.data;
};

export const changeRelationStatus = async (relationNo, status) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/myPage/changeRelationStatus`, null, {
        headers: {
            Authorization: auth,
        },
        params: {
            relationNo: relationNo,
            status: status,
        },
    });
    // console.log(result.data.data)
    return result.data.data;
};

export const deleteUserRelation = async (relationNo, type) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/myPage/deleteUserRelation`, null, {
        headers: {
            Authorization: auth,
        },
        params: {
            relationNo: relationNo,
            type: type,
        },
    });
    // console.log(relationNo, type)
    // console.log(result.data)
    return result.data.data;
};

export const getUsage = async (fromRequestedDatetime, toRequestedDatetime, isIncludeSlave, userId) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/myPage/getUsage`, null, {
        headers: {
            Authorization: auth,
        },
        params: {
            fromRequestedDatetime: fromRequestedDatetime,
            toRequestedDatetime: toRequestedDatetime,
            isIncludeSlave: isIncludeSlave,
            pageNum: 1,
            pageSize: 9999,
            userId: userId,
            periodType: 'day',
        },
    });
    // console.log(result)
    if (result === 500) return 'server error : 500';
    if (result.data.data === 'NO_DATA') return result.data.data;

    return result.data.data;
};

export const getSubAccount = async (svcNo) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/console/getSubAccount`, null, {
        headers: {
            Authorization: auth,
        },
        params: {
            masterSvcNo: svcNo,
        },
    });
    return result.data.data;
};

export const checkSubAccountName = async (subAccountName) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/console/checkSubAccountName`, null, {
        headers: {
            Authorization: auth,
        },
        params: {
            name: subAccountName,
        },
    });
    return result.data.data;
};

export const regiSubAccount = async (svcNo, subject, description) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/console/regiSubAccount`, null, {
        headers: {
            Authorization: auth,
        },
        params: {
            svcNo: svcNo,
            subject: subject,
            description: description,
        },
    });
    return result.data;
};

export const modifySubAccount = async (accountNo, callbackUrl, description) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/console/modifySubAccount`, null, {
        headers: {
            Authorization: auth,
        },
        params: {
            accountNo: accountNo,
            callbackUrl: callbackUrl,
            description: description,
        },
    });
    return result.data;
};

export const deleteSubAccount = async (accountNo) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/console/deleteSubAccount`, null, {
        headers: {
            Authorization: auth,
        },
        params: {
            accountNo: accountNo,
        },
    });
    return result.data;
};

export const getSubAccountDetail = async (accountNo) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/console/getSubAccountDetail`, null, {
        headers: {
            Authorization: auth,
        },
        params: {
            accountNo: accountNo,
        },
    });
    return result.data.data;
};

export const reissueSecretKey = async (accountNo) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/console/reissueSecretKey`, null, {
        headers: {
            Authorization: auth,
        },
        params: {
            accountNo: accountNo,
        },
    });
    return result.data.data;
};

export const getUsageDetail = async (data) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/myPage/getUsage/detail`, null, {
        headers: {
            Authorization: auth,
        },
        params: {
            fromRequestedDatetime: data.fromRequestedDatetime,
            isIncludeSlave: data.isIncludeSlave,
            toRequestedDatetime: data.toRequestedDatetime,
            serviceNo: data.serviceNo,
            promotionNo: data.promotionNo,
            statusName: data.statusName,
            periodType: 'day',
        },
    });
    if (result == 500) return 'err';
    return result.data.data;
};

export const getInquiryList = async (email, pageNumber, pageSize) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;

    let form = new FormData();
    form.append('email', email);
    form.append('pageNumber', pageNumber);
    form.append('pageSize', pageSize);

    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/board/getInquiryList`, form, {
        headers: {
            Authorization: auth,
        },
    });
    return result.data;
};

export const getInquiryDetail = async (email, inquiryNo, password) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    let form = new FormData();
    form.append('email', email);
    form.append('inquiryNo', inquiryNo);
    form.append('password', password);

    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/board/getInquiryDetail`, form, {
        headers: {
            Authorization: auth,
        },
    });

    return result.data.data;
};

export const getPromotionName = async (promotionNo) => {
    if (promotionNo == 0) return '-';
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/svc/getPromotionDetail`, null, {
        params: {
            promotionNo: promotionNo,
        },
    });
    return result.data.data.promotion.subject;
};

export const getSalePrice = async (svcNo) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/svc/getSvcPrice`, null, {
        headers: {
            Authorization: auth,
        },
        params: {
            svcNo: svcNo,
        },
    });
    return result.data.data;
};

export const getUserAuthList = async () => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.get(process.env.REACT_APP_API_SERVER_URL+`/api/myPage/getUserAuthList`, {
        headers: {
            Authorization: auth,
        },
    });
    return result.data.data;
};

export const changeUserAuthStatus = async (authNo, enabled) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/myPage/changeUserAuthStatus`, null, {
        headers: {
            Authorization: auth,
        },
        params: {
            authNo: authNo,
            enabled: !enabled,
        },
    });

    return result.data.data;
};

export const deleteUserAuth = async (authNo) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/myPage/deleteUserAuth`, null, {
        headers: {
            Authorization: auth,
        },
        params: {
            authNo: authNo,
        },
    });
    // console.log(result.data.data)
    return result.data.data;
};

export const saveUserAuth = async (name, tel) => {
    let form = new FormData();
    form.append('name', name);
    form.append('tel', tel);

    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/myPage/saveUserAuth`, form, {
        headers: {
            Authorization: auth,
            'Content-type': 'application/x-www-form-urlencoded',
        },
    });
    return result.data.data;
};

export const checkIdandPw = (id, pwd) => {
    let idArr = [];
    let result = true;
    for (let i = 0; i < id.length - 2; i++) {
        idArr.push(id.slice(i, i + 3));
    }
    for (const element of idArr) {
        if (!pwd.indexOf(element) === -1) {
            result = false;
            break;
        }
    }
    return result;
};

export const checkPassword2 = (currentPassword, currentPassword2) => {
    return currentPassword == currentPassword2;
};

export const duplicateCheckUserId = async (id) => {
    let form = new FormData();
    form.append('userId', id);
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/user/checkId`, form, {
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
        },
    });
    return result.data.data;
};

export const recoverUser = async (data) => {
    let form = new FormData();
    form.append('id', data.id);
    form.append('kCertNum', data.kCertNum);
    form.append('recCert', data.recCert);
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/user/recover`, form, {
        headers: {
            Authorization: auth,
            'Content-type': 'application/x-www-form-urlencoded',
        },
    });
    return result.data.data;
};

export const getUserAuthParameter = async (data) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/user/getUserAuthParameter`, null, {
        headers: {
            Authorization: auth,
        },
        params: {
            birthDay: data.birthDay,
            callbackUrl: data.callbackUrl,
            gender: data.gender,
            name: data.name,
            nation: data.nation,
            phoneCorp: data.phoneCorp,
            phoneNo: data.phoneNo,
            type: data.type,
        },
    });

    return result.data.data;
};

export const checkPassword = (e) => {
    const regExp = /(?=.*\d{1,50})(?=.*[~`!@#$%\^&*()-+=]{1,50})(?=.*[a-zA-Z]{1,50}).{8,50}$/;
    return regExp.test(e);
};

export const checkPassword_advence = (validid, validpw) => {
    if (idValuePw()) {
        return '비밀번호가 아이디와 동일하거나 4자리 이상 일치할 경우 사용할 수 없습니다.';
    }
    if (pwSame()) {
        return '비밀번호에 4자리 이상 연속된 동일한 문자(예:aaaa)는 사용할 수 없습니다.';
    }
    if (pwContinue()) {
        return '비밀번호에 4자리 이상 연속된 문자(예:abcd)는 사용할 수 없습니다.';
    }

    function idValuePw() {
        let tmp = '';
        let cnt = 0;
        for (let i = 0; i < validid.length - 2; i++) {
            tmp = validid.charAt(i) + validid.charAt(i + 1) + validid.charAt(i + 2) + validid.charAt(i + 3);
            if (validpw.indexOf(tmp) > -1) {
                cnt = cnt + 1;
            }
        }
        return cnt > 0;
    }

    function pwSame() {
        let tmp = '';
        let cnt = 0;

        for (let i = 0; i < validpw.length; i++) {
            tmp = validpw.charAt(i);
            if (tmp == validpw.charAt(i + 1) && tmp == validpw.charAt(i + 2) && tmp == validpw.charAt(i + 3)) {
                cnt = cnt + 1;
            }
        }
        return cnt > 0;
    }

    function pwContinue() {
        let cnt = 0;
        let cnt2 = 0;
        let tmp = '';
        let tmp2 = '';
        let tmp3 = '';
        let tmp4 = '';

        for (let i = 0; i < validpw.length; i++) {
            tmp = validpw.charAt(i);
            tmp2 = validpw.charAt(i + 1);
            tmp3 = validpw.charAt(i + 2);
            tmp4 = validpw.charAt(i + 3);
            if (
                tmp.charCodeAt(0) - tmp2.charCodeAt(0) == 1 &&
                tmp2.charCodeAt(0) - tmp3.charCodeAt(0) == 1 &&
                tmp3.charCodeAt(0) - tmp4.charCodeAt(0) == 1
            ) {
                cnt = cnt + 1;
            }
            if (
                tmp.charCodeAt(0) - tmp2.charCodeAt(0) == -1 &&
                tmp2.charCodeAt(0) - tmp3.charCodeAt(0) == -1 &&
                tmp3.charCodeAt(0) - tmp3.charCodeAt(0) == -1
            ) {
                cnt2 = cnt2 + 1;
            }
        }
        return !!(cnt > 0 || cnt2 > 0);
    }
    return false;
};

export const checkSubAccountRule = (e) => {
    const regExp = /^[a-zA-Z0-9_]{0,20}$/;
    return regExp.test(e);
};

export const checkMobile = (e) => {
    if (t('langNo') == 1) {
        const koreanPhoneNumberRegex = /^01([016789])([0-9]{3,4})([0-9]{4})$/;
        //const regExp = /^01([0|1|6|7|8|9])?([0-9]{3,4})?([0-9]{4})$/;
        return koreanPhoneNumberRegex.test(e);
    } else {
        const regExp = /^\+[0-9]{0,14}/;
        return regExp.test(e);
    }
};

export const checkEmail = (e) => {
    const regExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]+$/;
    return regExp.test(e);
};

export const checkUserID = (id) => {
    const regExp = /^[a-zA-Z0-9]{4,12}$/;
    return regExp.test(id);
};

export const checkAdminCheckCallNumber = (e) => {
    const regExp = /^[0-9]{8,11}$/;
    return regExp.test(e);
};

export const openKmcert = (reqKMCISForm) => {
    let url = 'https://www.kmcert.com/kmcis/web/kmcisReq.jsp';
    let title = 'KMCIS_window';

    let status = 'toolbar=no,scrollbars=no,resizable=yes,status=no,menubar=no,width=425, height=550, top=250,left=435';
    window.open('', title, status);

    reqKMCISForm.target = title;
    reqKMCISForm.action = url;
    reqKMCISForm.method = 'post';
    reqKMCISForm.submit();
    reqKMCISForm.target = '';
};

export const getEventDetail = async (eventNo) => {
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/board/getEventDetail`, null, {
        params: {
            eventNo: eventNo,
        },
    });
    return result.data.data;
};

export const getUserNameAndPhoneByEncData = async (kCertNum, recCert) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/user/getUserNameAndPhoneByEncData`, null, {
        headers: {
            Authorization: auth,
        },
        params: {
            kCertNum: kCertNum,
            recCert: recCert,
        },
    });
    // console.log(result.data)
    return result.data;
};

export const getPopupNotice = async (langNo) => {
    const result = await axios.get(process.env.REACT_APP_API_SERVER_URL+`/api/main/getPopupNotice`, {
        params: {
            langNo: langNo,
        },
    });
    return result.data.data;
};

export const changePasswordWithAuth = async (kCertNum, newPassword, recCert, userId) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;

    let form = new FormData();
    form.append('kCertNum', kCertNum);
    form.append('newPassword', newPassword);
    form.append('recCert', recCert);
    form.append('userId', userId);

    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/user/changePasswordWithAuth`, form, {
        headers: {
            Authorization: auth,
            'Content-type': 'application/x-www-form-urlencoded',
        },
    });

    return result.data.data;
};

export const changePassword = async (currentPassword, newPassword) => {
    let form = new FormData();
    form.append('currentPassword', currentPassword);
    form.append('newPassword', newPassword);

    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/myPage/changePassword`, form, {
        headers: {
            Authorization: auth,
            'Content-type': 'application/x-www-form-urlencoded',
        },
    });
    // console.log(result.data.data)
    return result.data.data;
};

export const changeSubAccountUse = async (accountNo, use) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/console/changeSubAccountUse`, null, {
        header: {
            Authorization: auth,
        },
        params: {
            accountNo: accountNo,
            use: use,
        },
    });
    return result.data.data;
};

export const getPaymentHash = async (amountTotal) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/myPage/getPaymentHash`, null, {
        headers: {
            Authorization: auth,
        },
        params: {
            amountTotal: amountTotal,
        },
    });

    return result.data.data;
};

export const openPayment = (sendForm, payURI) => {
    let url = `https://pay.cjsystems.co.kr/${payURI}`;
    // let url    = `https://devpay.cjsystems.co.kr/${payURI}`;

    let title = 'Payment_window';

    let status = 'toolbar=no,scrollbars=no,resizable=yes,status=no,menubar=no,width=425, height=550, top=250,left=435';
    window.open('', title, status);

    sendForm.target = title;
    sendForm.action = url;
    sendForm.method = 'post';
    sendForm.submit();
    sendForm.target = '';
};

export const getSecessionType = async () => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.get(process.env.REACT_APP_API_SERVER_URL+`/api/myPage/getSecessionType`, {
        headers: {
            Authorization: auth,
        },
    });
    return result.data.data;
};

export const withdrawal = async (secessionReason, secessionTypeNo, password) => {
    let form = new FormData();
    form.append('secessionReason', secessionReason);
    form.append('secessionTypeNo', secessionTypeNo);
    form.append('password', password);

    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/myPage/withdrawal`, form, {
        headers: {
            Authorization: auth,
            'Content-type': 'application/x-www-form-urlencoded',
        },
    });
    return result.data.data;
};

export const getTwoFactorAuthList = async (id, pwd) => {
    let form = new FormData();

    form.append('id', id);
    form.append('password', pwd);

    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/user/getTwoFactorAuthList`, form, {
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
        },
        params: {},
    });
    return result.data;
};

export const sendTwoFactorAuthSMS = async (authNo, id, pwd) => {
    let form = new FormData();
    form.append('authNo', authNo);
    form.append('id', id);
    form.append('password', pwd);

    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/user/sendTwoFactorAuthSMS`, form, {
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
        },
    });
    return result.data.data;
};

export const verifyRecaptcha = async (token) => {
    let form = new FormData();
    form.append('token', token);

    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+'/api/user/verifyRecaptcha', form);
    return result.data;
}

export const checkNeedRecaptcha = async (userId) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/user/checkNeedRecaptcha`, {userId:userId}, {
        headers: {
            Authorization: auth,
            'Content-type': 'application/json',
        },
    });
    return result.data.data;
}

export const login = async (authKey, authNo, id, password) => {
    let form = new FormData();

    authKey && form.append('authKey', authKey);
    authKey && form.append('authNo', authNo);
    form.append('id', id);
    form.append('password', password);

    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/user/login`, form, {
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
        },
    });
    return result.data;
};

export const getSenderNo = async () => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/console/getSenderNo`, null, {
        headers: {
            Authorization: auth,
        },
    });
    return result.data.data;
};

export const reqAdminCertify = async (phone, businessRegistration, serviceUseCertificate, poa) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    let formData = new FormData();
    formData.append('businessRegistration', businessRegistration[0]);
    formData.append('serviceUseCertificate', serviceUseCertificate[0]);
    poa && formData.append('poa', poa[0]);
    formData.append('phone', phone);
    return await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/console/reqAdminCertify`, formData, {
        headers: {
            Authorization: auth,
            'Content-type': 'application/x-www-form-urlencoded',
        },
    });
};

export const reqSMSAdminCertify = async (phone, businessRegistration, certificateOfEmployment, poa) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    let formData = new FormData();
    formData.append('businessRegistration', businessRegistration[0]);
    formData.append('certificateOfEmployment', certificateOfEmployment[0]);
    poa && formData.append('poa', poa[0]);
    formData.append('phone', phone);
    return await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/console/reqSMSAdminCertify`, formData, {
        headers: {
            Authorization: auth,
            'Content-type': 'application/x-www-form-urlencoded',
        },
    });
};

export const requestSenderNoCert = async (certType, phone) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    let form = new FormData();
    form.append('certType', certType);
    form.append('phone', phone);

    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/console/requestSenderNoCert`, form, {
        headers: {
            Authorization: auth,
            'Content-type': 'application/x-www-form-urlencoded',
        },
    });
    return result.data.data;
};

export const certifyAuthNumber = async (authCode, authKey, phone) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    let form = new FormData();
    form.append('authCode', authCode);
    form.append('authKey', authKey);
    form.append('phone', phone);

    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/console/certifyAuthNumber`, form, {
        headers: {
            Authorization: auth,
            'Content-type': 'application/x-www-form-urlencoded',
        },
    });
    return result.data.data;
};

export const changeUserInfo = async (data, files) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;

    let form = files;
    if (!files) {
        form = new FormData();
    }
    form.set('businessItem', data.businessItem);
    form.set('businessType', data.businessType);
    form.set('ceoName', data.ceoName);
    form.set('companyAddress', data.companyAddress);
    form.set('companyAddressDetail', data.companyAddressDetail);
    form.set('companyName', data.companyName);
    form.set('companyRegNumber', data.companyRegNumber);
    form.set('email', data.email);
    form.set('managerName', data.managerName);
    form.set('phone', data.phone);
    form.set('paymentType', data.paymentType);
    form.set('recvNewsletter', data.recvNewsletter);
    form.set('recvSms', data.recvSms);
    form.set('consentUserInfoTerm', data.consentUserInfoTerm);
    form.set('consent3rdInfo', data.consent3rdInfo);
    form.set('overseasTransferOfPInfo', data.overseasTransferOfPInfo);
    form.set('kCertNum', data.kCertNum);
    form.set('recCert', data.recCert);
    form.set('privacyAgr', data.privacyAgr);

    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/myPage/changeUserInfo`, form, {
        headers: {
            Authorization: auth
        },
    });

    return result.data;
};

export const getServiceApplication = async (svcNo) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/console/getServiceApplication`, null, {
        headers: {
            Authorization: auth,
        },
        params: {
            svcNo: svcNo,
        },
    });
    return result.data.data;
};

export const getApplicationData = async (svcNo) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/console/getApplicationData`, null, {
        headers: {
            Authorization: auth,
        },
        params: {
            svcNo: svcNo,
        },
    });
    return result.data.data;
};

export const getServiceHistory = async (accountNo, fromRequestedDatetime, pageNum, pageSize, toRequestedDateTime, searchType, queryText) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/console/getServiceHistory`, null, {
        headers: {
            Authorization: auth,
        },
        params: {
            accountNo: accountNo,
            fromRequestedDatetime: fromRequestedDatetime,
            pageNum: pageNum,
            pageSize: pageSize,
            toRequestedDateTime: toRequestedDateTime,
            queryText: queryText,
            searchType: searchType
        },
    });

    return result.data;
};

export const getNoticeCategory = async (langNo) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+'/api/board/getNoticeCategory', null, {
        headers: {
            Authorization: auth,
        },
        params: {
            langNo: langNo,
        },
    });
    return result.data.data;
};

export const getNotice = async (data) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+'/api/board/getNotice', null, {
        headers: {
            Authorization: auth,
        },
        params: {
            cateNo: data.cateNo,
            langNo: data.langNo,
            startDate: data.startDate,
            endDate: data.endDate,
            query: data.query,
            pageSize: data.pageSize,
            pageNumber: data.pageNumber,
        },
    });
    return result.data;
};

export const getNoticeDetail = async (data) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/board/getNoticeDetail`, null, {
        headers: {
            Authorization: auth,
        },
        params: {
            cateNo: data.cateNo,
            noticeNo: data.noticeNo,
        },
    });
    return result.data.data;
};

export const getFaqCategory = async (langNo) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/board/getFaqCategory`, null, {
        headers: {
            Authorization: auth,
        },
        params: {
            langNo: langNo,
        },
    });
    return result.data.data;
};

export const getFaq = async (data) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/board/getFaq`, null, {
        headers: {
            Authorization: auth,
        },
        params: {
            cateNo: data.cateNo,
            langNo: data.langNo,
            pageNumber: data.pageNumber,
            pageSize: data.pageSize,
            query: data.query,
        },
    });
    return result.data.data;
};

export const getAlimProfile = async (data) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.get(process.env.REACT_APP_API_SERVER_URL+`/api/console/getATProfile`, {
        headers: {
            Authorization: auth,
        },
        params: {
            userId: data.userId,
            senderKey: data.senderKey,
            uuid: data.uuid,
            name: data.name,
            profileStat: null
        },
    });
    return result.data.data;
};

export const getAlimUuidProfile = async (data) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.get(process.env.REACT_APP_API_SERVER_URL+`/api/console/getATUuidProfile`, {
        headers: {
            Authorization: auth,
        },
        params: {
            uuid: data.uuid,
        },
    });
    return result.data.data;
};

export const getATProfileCategory = async (data) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/console/getATProfileCategory`, null, {
        headers: {
            Authorization: auth,
        },
        params: {
            userId: data.userId,
            catCode: data.catCode,
        },
    });
    // console.log(`알림톡 카테고리 조회 결과`)
    return result.data.data.data.list;
};

export const getKakaoToken = async (data) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/console/getKakaoToken`, null, {
        headers: {
            Authorization: auth,
        },
        params: {
            userId: data.userId,
            plusFriend: data.plusFriend,
            pNumber: data.pNumber,
        },
    });
    // console.log(`토큰신청 결과`)
    // console.log(result.config.params)
    // console.log(result)
    return result;
};

export const saveSenderProfile = async (data) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/console/saveSenderProfile`, null, {
        headers: {
            Authorization: auth,
        },
        params: {
            senderKey : data.senderKey,
            userId : data.userId
        },
    });

    return result.data;
}

export const regiATProfile = async (data) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/console/regiATProfile`, null, {
        headers: {
            Authorization: auth,
        },
        params: {
            token: data.token,
            pNumber: data.pNumber,
            userId: data.userId,
            catCode: data.catCode,
            plusFriend: data.plusFriend,
        },
    });

    // console.log(`발신프로필 등록 결과`)
    // console.log(result.config.params)
    // console.log(result)

    return result.data.data;
};

// 발신프로필 휴면 해제
export const recoverATProfile = async (data) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/console/recoverATProfile`, null, {
        headers: {
            Authorization: auth,
        },
        params: {
            userId: data.userId,
            senderKey: data.senderKey,
        },
    });
    return result.data;
};

// 알림톡 템플릿 등록
export const regiATTemplate = async (data) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;

    let form = new FormData();
    form.append('userId', data.userId);
    form.append('senderKey', data.senderKey);
    form.append('senderKeyType', data.senderKeyType || 'S');
    form.append('templtCode', data.templtCode);
    form.append('templtName', data.templtName);
    form.append('templtMessageType', data.templtMessageType);
    form.append('templtEmphasizeType', data.templtEmphasizeType || 'NONE');
    form.append('templtContent', data.templtContent);
    form.append('templtExtra', data.templtExtra);
    form.append('templtAd', data.templtAd);
    form.append('templtImageName', data.templtImageName);
    form.append('templtImageUrl', data.templtImageUrl);
    form.append('templtTitle', data.templtTitle);
    form.append('templtSubtitle', data.templtSubtitle);
    form.append('catCode', data.catCode);
    form.append('securityFlag', data.securityFlag);
    form.append('buttons', data.buttons.length == 0 ? [] : JSON.stringify(data.buttons));
    form.append('quickReplies', data.quickReplies.length == 0 ? [] : JSON.stringify(data.quickReplies));
    form.append('templateHeader', data.templateHeader);
    form.append('templateItemHighlight', !data.templateItemHighlight ? {} : JSON.stringify(data.templateItemHighlight));
    form.append('templateItem',  !data.templateItem ? {} : JSON.stringify(data.templateItem));

    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/console/regiATTemplate`, form, {
        headers: {
            Authorization: auth,
            'Content-type': 'application/x-www-form-urlencoded',
        },
    });
    return result.data;
};

// 알림톡 템플릿 카테고리 불러오기
export const getTemplateCategory = async (data) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.get(process.env.REACT_APP_API_SERVER_URL+`/api/console/getTemplateCategory`, {
        headers: {
            Authorization: auth,
        },
        params: {
            userId: data.userId,
            senderKey: data.senderKey,
            catCode: data.catCode,
        },
    });
    return result.data;
};

// 조건에 따라 알림톡 템플릿 목록 불러오기
export const getATTemplateList = async (data) => {
    if(data.userId && data.senderKey) {
        const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
        const result = await axios.get(process.env.REACT_APP_API_SERVER_URL+`/api/console/getATTemplateList`, {
            headers: {
                Authorization: auth,
            },
            params: {
                userId: data.userId,
                senderKey: data.senderKey,
                senderKeyType: data.senderKeyType || 'S',
                templtCode: data.templtCode,
                templtName: data.templtName,
                inspStatus: data.inspStatus,
                status: data.status,
                block: data.block,
                dormant: data.dormant,
            },
        });
        return result.data;
    } else {
        return {
            data: {
                list: [],
                total: 0,
            },
        };
    }
};

//senderKeys의 템플릿 상세 데이터 목록을 Pagination으로 불러오기
export const getATTemplateListPaging = async (data) => {
    if(data.userId && data.senderKeys) {
        const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
        const result = await axios.get(process.env.REACT_APP_API_SERVER_URL+`/api/console/getATTemplateListPaging`,
            {
                headers: {
                    Authorization: auth,
                },
                params: {
                    userId : data.userId,
                    senderKeys : data.senderKeys,
                    pageNum: data.pageNum, 
                    pageSize: data.pageSize,
                    
                    senderKeyType: data.senderKeyType || 'S',
                    templtCode: data.templtCode,
                    templtName: data.templtName,
                    inspStatus: data.inspStatus,
                    status: data.status,
                    block: data.block,
                    dormant: data.dormant,
                }
            }
        );
        return result.data;
    } else {
        return {
            data : [],
            code : 2
        };
    }
}

// 알림톡 템플릿 상세사항 불러오기
export const getATTemplateDetail = async (data) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/console/getATTemplateDetail`, null, {
        headers: {
            Authorization: auth,
        },
        params: {
            userId: data.userId,
            senderKey: data.senderKey,
            templtCode: data.templtCode,
            senderKeyType: data.senderKeyType || 'S',
        },
    });
    return result.data;
};

export const getImageList = async (data) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/console/getImageList`, null, {
        headers: {
            Authorization: auth,
        },
        params: {
            image: data.image,
            type: data.type,
            userId: data.userId,
        },
    });
    return result.data;
};

// 알림톡 템플릿 삭제하기
export const deleteTemplate = async (data) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/console/deleteTemplate`, null, {
        headers: {
            Authorization: auth,
        },
        params: {
            senderKeyType: data.senderKeyType,
            templtCode: data.templtCode,
            senderKey: data.senderKey,
            userId: data.userId,
        },
    });
    return result.data;
};

// 알림톡 템플릿 파일로 대량 등록
export const createTemplates = async (data) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/console/createTemplates`, data, {
        headers: {
            Authorization: auth,
            'Content-type': 'multipart/form-data',
        },
    });
    // console.log(result.data)
    return result.data;
};

// 카카오톡 메세지용 이미지 업로드
export const uploadImage = async (data) => {
    let form = new FormData();
    form.append('image', data.image);
    form.append('type', data.type);
    form.append('userId', data.userId);
    // console.log(data.image)
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/console/uploadImage`, form, {
        headers: {
            Authorization: auth,
            'Content-type': 'multipart/form-data',
        },
    });
    // console.log(result.data)
    return result.data;
};

// 템플릿 사용 중지
export const stopTemplate = async (data) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/console/stopTemplate`, null, {
        headers: {
            Authorization: auth,
            'Content-type': 'multipart/form-data',
        },
        params: {},
    });
    return result.data;
};

// 템플릿 사용 중지 해제
export const reuseTemplate = async (data) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/console/reuseTemplate`, null, {
        headers: {
            Authorization: auth,
            'Content-type': 'multipart/form-data',
        },
        params: {},
    });
    return result.data;
};

// 템플릿 검수 요청
export const requestTemplateInspect = async (data) => {
    // 템플릿 검수 요청
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/console/requestTemplateInspect`, null, {
        headers: {
            Authorization: auth,
        },
        params: {
            userId: data.userId,
            senderKey: data.senderKey,
            templtCode: data.templtCode,
            senderKeyType: data.senderKeyType,
            commentContent: data.commentContent,
        },
    });
    return result.data;
};

// 템플릿 검수 요청을 파일과 함께 보내기
export const requestTemplateInspectWithFile = async (data, attachment) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;

    let form = new FormData();

    if(attachment && attachment.length >= 0) {
        attachment.forEach((file) => {
            form.append('attachment', file);
        })
    }


    form.append('data', new Blob([JSON.stringify({
        userId : data.userId,
        senderKey : data.senderKey,
        templtCode : data.templtCode,
        senderKeyType : data.senderKeyType,
        commentContent : data.commentContent,
    })], { type: 'application/json' }));

    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/console/requestTemplateInspectWithFile`, form, {
        headers: {
            Authorization: auth,
            'Content-Type': 'multipart/form-data'
        }
    });

    return result.data;
};

// 템플릿 검수 요청 취소
export const cancelTemplateInspect = async (data) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/console/cancelTemplateInspect`, null, {
        headers: {
            Authorization: auth,
        },
        params: {
            userId: data.userId,
            senderKey: data.senderKey,
            templtCode: data.templtCode,
            senderKeyType: data.senderKeyType,
        },
    });
    return result.data;
};

// 템플릿 승인 취소 요청 보내기
export const cancelTempltApproval = async (data) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    const res = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/console/cancelApproval`, null, {
        headers: {
            Authorization: auth,
        },
        params: {
            userId: data.userId,
            senderKey: data.senderKey,
            templtCode: data.templtCode,
            senderKeyType: data.senderKeyType
        }

    });
    return res.data;
};

// 템플릿 수정 요청
export const modifyTemplateInspect = async (data) => {
    let form = new FormData();
    form.append('userId', data.userId);
    form.append('senderKey', data.senderKey);
    form.append('senderKeyType', data.senderKeyType || 'S');
    form.append('templtCode', data.templtCode);
    form.append('templtName', data.templtName);
    form.append('templtMessageType', data.templtMessageType);
    form.append('templtEmphasizeType', data.templtEmphasizeType || 'NONE');
    form.append('templtContent', data.templtContent);
    form.append('templtExtra', data.templtExtra);
    form.append('templtAd', data.templtAd);
    form.append('templtImageName', data.templtImageName);
    form.append('templtImageUrl', data.templtImageUrl);
    form.append('templtTitle', data.templtTitle);
    form.append('templtSubtitle', data.templtSubtitle);
    form.append('catCode', data.catCode);
    form.append('securityFlag', data.securityFlag);
    form.append('buttons', data.buttons.length == 0 ? [] : JSON.stringify(data.buttons));
    form.append('quickReplies', data.quickReplies.length == 0 ? [] : JSON.stringify(data.quickReplies));
    form.append('templateHeader', data.templateHeader);
    form.append('templateItemHighlight', !data.templateItemHighlight ? {} : JSON.stringify(data.templateItemHighlight));
    form.append('templateItem',  !data.templateItem ? {} : JSON.stringify(data.templateItem));

    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    // console.log(data)
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/console/modifyTemplateInspect`, form, {
        headers: {
            Authorization: auth,
            'Content-type': 'application/x-www-form-urlencoded',
        },
    });
    // console.log(result)
    return result.data;
};

export const getApplicationHistory = async (svcNo) => {
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : isNull;
    const result = await axios.post(
        process.env.REACT_APP_API_SERVER_URL+`/api/console/getApplicationHistory`,
        {
            svcNoList: svcNo,
        },
        {
            headers: {
                Authorization: auth,
            },
            params: {
                svcNoList: svcNo,
            },
        }
    );
    return result.data.data;
};

export const saveDownloadInfo = async (form) => {
    const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/main/saveDownloadInfo`, form);

    return result.data;
}