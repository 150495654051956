import React, { useEffect, useRef, useState } from 'react'

//context
import { GlobalContext } from './shared/globalContext'
import { TopButtonContext } from './shared/topButtonContext'

// using package
import i18n from './lang/i18n';
import AOS from 'aos';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { isMobile, isIOS } from 'react-device-detect';

// css
import './index.css';
import 'aos/dist/aos.css';

// layout
import Header from './Layouts/Header';
import Footer from './Layouts/Footer';
import MobileHeader from './Mobile/Header';
import MobileFooter from './Mobile/Footer';

// pages
import Main from           './Pages/Main/index';
import Service from        './Pages/Service';
import ApiServices from    './Pages/Service/ApiServices.js';
import MarketPlace from    './Pages/MarketPlace';
import Partner from        './Pages/Partner';
import Notice from         './Pages/Support/notice';
import Faq from            './Pages/Support/faq';
import Requests from       './Pages/Support/requests';
import RequestsSearch from './Pages/Support/requestsSearch';
import ApiAll from         './Pages/Support/apiAll';
import AboutUs from        './Pages/AboutUs';
import Mypage from         './Pages/Mypage';
import Login from          './Pages/login';
import SignUp from         './Pages/SignUp';
import Payment from        './Pages/Mypage/Payment';
import ServiceUsage from   './Pages/Mypage/ServiceUsage';
import Account from        './Pages/Mypage/Account';
import Group from          './Pages/Mypage/Group';
import ChangeProfile from  './Pages/Mypage/ChangeProfile';
import ChangePassword from './Pages/Mypage/ChangePassword';
import Security from       './Pages/Mypage/Security';
import KeyManagement from  './Pages/Mypage/KeyManagement';
import Secession from      './Pages/Mypage/Secession';
import getAccessToken from './Util/getAccessToken';
import PasswordFind from   './Pages/Mypage/PasswordFind';
import Event from          './Pages/Event';
import Error from          './Pages/Error';
import PaymentResult from  './Pages/PaymentResult.js';

// util
import MobileCheckResult from './Util/mobileCheckResult';
import { getSiteCategory } from './Util/reqApi';
import Company from "./Pages/Company";
import SearchResult from "./Pages/Search/SearchResult";
import RouteChangeTracker from './Util/RouteChangeTracker';

function App() {
    const [login, setLogin] = useState(false);
    const [menus, setMenus] = useState([]);
    const [searchStr, setSearchStr] = useState('');

    const focusRef = useRef(null);

    const setFocus = () => {
      if(focusRef.current) {
        focusRef.current.focus();
      }
    }

    useEffect(()=>{
        getAccessToken(setLogin);
        if(localStorage.getItem('lan')) i18n.changeLanguage(localStorage.getItem('lan'));
        else localStorage.setItem('lan', 'ko');

        getSiteCategory().then(result => {
            const menuUrl = ['service', 'marketplace', 'partnership', 'support/notice?cate=0&page=0', 'aboutus']
            // getSiteCategory API에서 받아온 데이터에 url을 추가해준다. (현재 API에서는 url이 없음 )
            const menuData = result.map((cateData,idx) => {
                cateData.url = `/${menuUrl[idx]}`
                return cateData;
            })
            setMenus(menuData)
        });

        AOS.init();

        //change page language setting
        document.documentElement.lang = localStorage.getItem('lan');
    },[])

    return(
        <GlobalContext.Provider value = {{ login, setLogin, menus, setMenus }} >
        
        {
            isMobile || (isIOS && isMobile)
            
            ?   // Mobile Routers
                <BrowserRouter>
                    <RouteChangeTracker/>
                    <MobileHeader/>
                        <div className="content" id="main" role="main">
                            <Routes>
                            {/* main */}
                                <Route path="/"                           element={<Main />} />
                            {/* service */}
                                <Route path="/service"                    element={<Service />} />
                                <Route path="/service/ApiServices/:api"   element={<ApiServices />} />
                            {/* marketplace */}
                                <Route path="/marketplace"                element={<MarketPlace />}/>
                            {/* partnership */}
                                <Route path="/partnership"                element={<Partner />}/>
                            {/* support */}
                                <Route path="/support/notice"             element={<Notice />}/>
                                <Route path="/support/noticeView/:id"     element={<Notice view={true}/>}/>
                                <Route path="/support/faq"                element={<Faq />}/>
                                <Route path="/support/requests"           element={<Requests />}/>
                                <Route path="/support/requestsSearch"     element={<RequestsSearch />}/>
                                <Route path="/support/apiAll"             element={<ApiAll />}/>
                            {/* aboutus */}
                                <Route path="/aboutus"                    element={<AboutUs />} />
                            {/* error */}
                                <Route path="/*"                          element={<Error />}/>
                            </Routes>
                        </div>
                    <MobileFooter />
                </BrowserRouter>

            :   // PC Routers
                <TopButtonContext.Provider value={{setFocus, focusRef}}>
                    <BrowserRouter>
                        <RouteChangeTracker/>
                        <Header searchStr={searchStr} setSearchStr={setSearchStr}/>
                        <div className="content" id="main" role="main">
                            <Routes>
                            {/* main */}
                                <Route path="/"                         element={<Main />} />
                            {/* service */}
                                <Route path="/service"                  element={<Service />} />
                                <Route path="/service/ApiServices/:api" element={<ApiServices />} />
                            {/* marketplace */}
                                <Route path="/marketplace"              element={<MarketPlace />}/>
                            {/* partnership */}
                                <Route path="/partnership"              element={<Partner />}/>
                            {/* company */}
                                <Route path="/company"                  element={<Company />}/>
                            {/* support */}
                                <Route path="/support/notice"           element={<Notice />}/>
                                <Route path="/support/noticeView/:id"   element={<Notice view={true}/>}/>
                                <Route path="/support/faq"              element={<Faq />}/>
                                <Route path="/support/requests"         element={<Requests />}/>
                                <Route path="/support/requestsSearch"   element={<RequestsSearch />}/>
                                <Route path="/support/apiAll"           element={<ApiAll />}/>
                            {/* aboutus */}
                                <Route path="/aboutus"                  element={<AboutUs />} />
                            {/* member */}
                                <Route path="/login"                    element={<Login />}/>
                                <Route path="/passwordFind"             element={<PasswordFind/>}/>
                                <Route path="/unlockAccount"            element={<PasswordFind unlock={true}/>}/>
                                <Route path="/userRecover"              element={<PasswordFind recover={true}/>}/>
                                <Route path="/prLUkzamrJh7Fgx2h22YQUF6DxqNmoZ2rTu1Opj9I5yZdNK/:step"             element={<SignUp/>}/>
                                <Route path="/mypage/*"                 element={<Mypage />}>
                                    <Route path="payment"               element={<Payment/>}/>
                                    <Route path="service"               element={<ServiceUsage/>}/>
                                    <Route path="group"                 element={<Group/>}/>
                                    <Route path="account/*"             element={<Account/>}>
                                        <Route path="changeProfile"     element={<ChangeProfile/>}/>
                                        <Route path="changePassword"    element={<ChangePassword/>}/>
                                        <Route path="security"          element={<Security/>}/>
                                        <Route path="keyManagement"     element={<KeyManagement/>}/>
                                        <Route path="secession"         element={<Secession/>}/>
                                    </Route>
                                </Route>
                            {/* other */}
                                <Route path="/event/:id"                element={<Event/>}/>
                                <Route path="/mobileCheckResult"        element={<MobileCheckResult/>}/>
                                <Route path="/paymentResult"            element={<PaymentResult/>}/>
                            {/* serach */}
                                <Route path="/search/:searchStr"        element={<SearchResult setSearchStr={setSearchStr}/>}/>
                            {/* error */}
                                <Route path="/*"                        element={<Error />}/>
                            </Routes>
                        </div>
                        <Footer/>
                    </BrowserRouter>
                </TopButtonContext.Provider>
        }
        </GlobalContext.Provider>
    )
}

export default App;