import React, { useState, useEffect, useRef } from 'react'
import { getCurrentDate } from '../../../Util/DateFormatter';
import { checkMobile, getPaymentHash, openPayment } from '../../../Util/reqApi';

const PayModal = (props) => {
    const { callModal, setCallModal, userInfor} = props;
    const [PayMethod, setPayMethod] = useState('');
    const [amount, setAmount] = useState(0);
    const [selectedPaymethod, setSelectedPaymethod] = useState('');
    const [CloseDate, setCloseDate] = useState(getCurrentDate(''));
    const [CJSHashData, setCJSHashData] = useState('');
    const [shopOrderNo, setShopOrderNo] = useState('');
    const [CardCode, setCardCode] = useState('');
    const [BankCd, setBankCd] = useState('');
    const [UserPhone, setUserPhone] = useState('');
    const [AccountOwner, setAccountOwner] = useState('');
    const [CashReceiptInfo, setCashReceiptInfo] = useState('');
    const [KeyInType, setKeyInType] = useState('');
    const [BatchKeyType, setBatchKeyType] = useState('');

    const selectedPaymethodURI = {
        0 : '/Olive/PayCreditCard.aspx',
        1 : '/Olive/PayVirtualAccount.aspx',
        2 : '/Olive/PayDirectAccount.aspx',
        3 : '/Olive/PayCardKeyIn.aspx', //정기결제
        4: '/EasyPay/EasyPayment.aspx', //네이버 페이
        5: '/EasyPay/EasyPayment.aspx' //카카오 페이
    }
    
    const paymentMethod = {
        0: 'Creditcard', //신용카드
        1: 'VirtualAccount', //가상계좌
        2: 'Account', //계좌이체
        3: 'Creditcard', //신용카드 정기결제
        4: 'NVA', //네이버 페이
        5: 'KKO' //카카오 페이
    }

    const cardCode = [
        {code : "BCC", name: "BC 카드"},
        {code : "HNB", name: "하나카드"},
        {code : "CNB", name: "KB 카드"},
        {code : "PHB", name: "우리카드"},
        {code : "WIN", name: "삼성카드"},
        {code : "NFF", name: "수협카드"},
        {code : "LGC", name: "신한카드"},
        {code : "CBB", name: "전북카드"},
        {code : "KEB", name: "하나카드(舊 외환카드)"},
        {code : "KJB", name: "광주비자"},
        {code : "DIN", name: "현대카드"},
        {code : "CJB", name: "제주카드"},
        {code : "AMX", name: "롯데카드"},
        {code : "CIT", name: "씨티카드"},
        {code : "NLC", name: "NH 카드"},
        {code : "SYH", name: "신협카드"},
        {code : "KKB", name: "카카오뱅크"}
    ]

    const bankCode_3 = [
        {code: "003", name : "기업"},
        {code: "034", name : "광주"},
        {code: "004", name : "국민"},
        {code: "037", name : "전북"},
        {code: "007", name : "수협"},
        {code: "039", name : "경남"},
        {code: "011", name : "농협"},
        {code: "045", name : "새마을금고"},
        {code: "020", name : "우리"},
        {code: "071", name : "우체국"},
        {code: "031", name : "대구"},
        {code: "081", name : "KEB 하나(외환, 하나 통합)"},
        {code: "032", name : "부산"},
        {code: "088", name : "신한"},
        {code: "089", name : "케이뱅크"}
    ]

    const sendForm = useRef();

    const handleCloseModal = () => {
        setCallModal(false)
    }

    useEffect(()=>{
        setPayMethod()
        setAmount(0)
        setSelectedPaymethod(0)
        setCloseDate()
        setCJSHashData()
        setShopOrderNo()
        setCardCode(cardCode[0].code)
        setBankCd(bankCode_3[0].code)
        setUserPhone()
        setAccountOwner()
        setCashReceiptInfo()
    },[])

    useEffect(()=>{
        setPayMethod()
        setCloseDate(getCurrentDate())
        setCJSHashData()
        setShopOrderNo()
        setCardCode(cardCode[0].code)
        setBankCd(bankCode_3[0].code)
        setUserPhone()
        setAccountOwner()
        setCashReceiptInfo()
    },[selectedPaymethod])

    return(
        <div id="PayMpop" className="pop_set_p">
            <div className="pop_section">
                <div className="parea">
                    <div className="page">
                        <div className="ppheader">
                            <div className="htit_t16">충전하기</div>
                            <div
                                id="PayMpopClose"
                                className="rclose"
                                onClick={handleCloseModal}
                            >
                                <img src={`/images/icon_pop_close.png`} alt="닫기"/>
                            </div>
                        </div>
                        <div className="pb_banner">
                            <img src={`/images/img_pat_banner.png`} alt="API PLEX CJPay결제"/>
                        </div>
                        <div className="p_in_section">
                            <div className="p_in_s_tit">상품정보</div>
                            <div className="p_in_s_info">
                                <table className="table_mypageD">
                                    <colgroup>
                                        <col width="12%" />
                                        <col width="*%" />
                                    </colgroup>
                                    <tbody>
                                        {/* <tr>
                                            <th>주문번호</th>
                                            <td><input type="text" /></td>
                                        </tr> */}
                                        <tr>
                                            <th>상품금액</th>
                                            <td>
                                                <input type="text" value={amount || ''} onChange={(e) => {setAmount(e.target.value)}}/>&nbsp;원&nbsp;&nbsp;
                                                <span>· 충전 금액을 입력해주세요. (예, 1,000,000원)</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>부가세</th>
                                            <td>
                                                {Math.floor(amount*0.1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>총결제금액</th>
                                            <td>
                                                {Math.floor(amount*1.1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="p_in_section_ps">
                            <div className="p_in_s_tit">결제방식</div>
                            <div className="p_in_s_info">
                                <div className="pay_list">
                                    <ul>
                                        <li>
                                            <div className={selectedPaymethod == 0 ? "pay_selt ps_on" : "pay_selt"} onClick={()=>{setSelectedPaymethod(0);}}>
                                                <div className="p_s_img">
                                                    <div className="img_area">
                                                        <img src={`/images/icon_pay_1.png`} alt=""/>
                                                    </div>
                                                </div>
                                                <div className="p_s_name">
                                                    <span>신용카드</span>    
                                                </div>
                                            </div>
                                        </li>    
                                        <li>
                                            <div className={selectedPaymethod == 4 ? "pay_selt ps_on" : "pay_selt"} onClick={()=>{setSelectedPaymethod(4);}}>
                                                <div className="p_s_img">
                                                    <div className="img_area">
                                                        <img src={`/images/icon_pay_6.png`} alt="" />
                                                    </div>
                                                </div>
                                                <div className="p_s_name">
                                                    <span>네이버페이</span>    
                                                </div>
                                            </div>
                                        </li>    
                                        <li>
                                            <div className={selectedPaymethod == 1 ? "pay_selt ps_on" : "pay_selt"} onClick={()=>{setSelectedPaymethod(1);}}>
                                                <div className="p_s_img">
                                                    <div className="img_area">
                                                        <img src={`/images/icon_pay_2.png`} alt="" />
                                                    </div>
                                                </div>
                                                <div className="p_s_name">
                                                    <span>가상계좌</span>    
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className={selectedPaymethod == 2 ? "pay_selt ps_on" : "pay_selt"} onClick={()=>{setSelectedPaymethod(2);}}>
                                                <div className="p_s_img">
                                                    <div className="img_area">
                                                        <img src={`/images/icon_pay_3.png`} alt=""/>
                                                    </div>
                                                </div>
                                                <div className="p_s_name">
                                                    <span>계좌이체</span>    
                                                </div>
                                            </div>
                                        </li>    
                                        <li>
                                            <div className={selectedPaymethod == 5 ? "pay_selt ps_on" : "pay_selt"} onClick={()=>{setSelectedPaymethod(5);}}>
                                                <div className="p_s_img">
                                                    <div className="img_area">
                                                        <img src={`/images/icon_pay_4.png`} alt=""/>
                                                    </div>
                                                </div>
                                                <div className="p_s_name">
                                                    <span>카카오페이</span>    
                                                </div>
                                            </div>
                                        </li>    
                                        <li>
                                            <div className={selectedPaymethod == 3 ? "pay_selt ps_on" : "pay_selt"} onClick={()=>{setSelectedPaymethod(3);}}>
                                                <div className="p_s_img">
                                                    <div className="img_area">
                                                        <img src={`/images/icon_pay_5.png`} alt=""/>
                                                    </div>
                                                </div>
                                                <div className="p_s_name">
                                                    <span>신용카드<br/>정기결제</span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        
                        <div className="p_in_section">
                            <div className="p_in_s_tit">추가 입력 정보</div>
                            <div className="p_in_s_info">
                                <table className="table_mypageD">
                                    <colgroup>
                                        <col width="24%" />
                                        <col width="*%" />
                                    </colgroup>
                                    <tbody>
                                        {
                                            (()=>{
                                                if(selectedPaymethod == 0){
                                                    return(
                                                        <tr>
                                                            <th>카드코드</th>
                                                            <td>
                                                                <select style={{width:"180px"}} onChange={(e)=>{setCardCode(e.target.value)}}>
                                                                    {
                                                                        cardCode.map(el=>{ return <option value={el.code} key={el.code}>{el.name}</option> })
                                                                    }
                                                                </select>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                if(selectedPaymethod == 1){
                                                    return(
                                                        <>
                                                            <tr>
                                                                <th>가상계좌 입금자 성명</th>
                                                                <td><input type="text" value={AccountOwner || ''} onChange={(e)=>{setAccountOwner(e.target.value)}} /></td>
                                                            </tr>
                                                            <tr>
                                                                <th>휴대폰 번호</th>
                                                                <td><input type="text" value={UserPhone || ''} onChange={(e)=>{setUserPhone(e.target.value)}} /></td>
                                                            </tr>
                                                        </>
                                                    )
                                                }
                                                // if(selectedPaymethod == 2){
                                                //     return(
                                                //         <>
                                                //             <tr>
                                                //                 <th>입금자 성명</th>
                                                //                 <td><input type="text" value={AccountOwner} onChange={(e)=>{setAccountOwner(e.target.value)}} /></td>
                                                //             </tr>
                                                //             <tr>
                                                //                 <th>휴대폰 번호</th>
                                                //                 <td><input type="text" value={UserPhone} onChange={(e)=>{setUserPhone(e.target.value)}} /></td>
                                                //             </tr>
                                                //             <tr>
                                                //                 <th>발급은행코드</th>
                                                //                 <td>
                                                //                     <select style={{width:"180px"}} onChange={(e)=>{setCardCode(e.target.value)}}>
                                                //                         {
                                                //                             bankCode_3.map(el=>{ return <option value={el.code} key={el.code}>{el.name}</option> })
                                                //                         }
                                                //                     </select>
                                                //                 </td>
                                                //             </tr>
                                                //             <tr>
                                                //                 <th>현금영수증 발행 구분</th>
                                                //                 <td>
                                                //                     <input type="radio" id="receipt1" name="receipt" onChange={(e)=>{setCashReceiptInfo('00')}} />
                                                //                     <label htmlFor="receipt1"><span></span>미발행</label>
                                                //                     &nbsp;
                                                //                     <input type="radio" id="receipt2" name="receipt" onChange={(e)=>{setCashReceiptInfo('01')}}/>
                                                //                     <label htmlFor="receipt2"><span></span>소득공제</label>
                                                //                     &nbsp;
                                                //                     <input type="radio" id="receipt3" name="receipt" onChange={(e)=>{setCashReceiptInfo('02')}}/>
                                                //                     <label htmlFor="receipt3"><span></span>지출증빙</label>
                                                //                 </td>
                                                //             </tr>
                                                //             <tr>
                                                //                 <th>현금영수증 발급 번호</th>
                                                //                 <td>
                                                //                     <input type="text" onChange={(e)=>{setCashReceiptInfo(e.target.value)}} />
                                                //                 </td>
                                                //             </tr>
                                                //         </>
                                                //     )
                                                // }
                                                if(selectedPaymethod == 3){
                                                    return(
                                                        <tr>
                                                            <th>카드코드</th>
                                                            <td>
                                                                <select style={{width:"180px"}} onChange={(e)=>{setCardCode(e.target.value)}}>
                                                                    {
                                                                        cardCode.map(el=>{ return <option value={el.code} key={el.code}>{el.name}</option> })
                                                                    }
                                                                </select>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            })()
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="btn_area">
                            <button className="btn_sm8" onClick={()=>{
                                getPaymentHash(Math.floor(amount*1.1)).then(result => {
                                    setKeyInType()
                                    setBatchKeyType()
                                    if(amount < 1000){alert('결제금액은 1,000원 이상입니다.'); return}

                                    if(selectedPaymethod == 0){
                                        if(!CardCode) return
                                    }
                                    if(selectedPaymethod == 1){
                                        if(!AccountOwner){alert('가상계좌 입금자명을 입력해주세요.'); return}
                                        if(!UserPhone){alert('휴대폰번호를 입력해주세요.'); return}
                                        if(!checkMobile(UserPhone)){alert('휴대폰번호를 확인해주세요.'); return}
                                    }

                                    if(!userInfor?.userStatus?.paymentAvailable){
                                        alert('결제가 불가능한 회원입니다.')
                                        return
                                    }

                                    if(selectedPaymethod == 3){
                                        setKeyInType('KeyTran')
                                        setBatchKeyType(1)
                                    }
                                

                                    setPayMethod(paymentMethod[selectedPaymethod]);
                                    setCJSHashData(result.hashData);
                                    setShopOrderNo(result.shopOrderNo);
                                    // console.log(sendForm.current)
                                    openPayment(sendForm.current, selectedPaymethodURI[selectedPaymethod])

                                    window.addEventListener("message", function(event) {
                                        if (event.origin !== "http://localhost:8082") {
                                            console.warn("Untrusted origin:", event.origin);
                                            return;
                                        }
                                        alert(event.data);
                                        setCallModal(false);
                                      }, false);
                                })
                            }}>결제하기</button>
                        </div>
                    </div>
                </div>
            </div>

            <form id="sendForm" name="sendForm" acceptCharset="utf-8" ref={sendForm}>
                <input type="hidden" id="Device " name="Device" value="PC"/> {/*결제방법*/}
                <input type="hidden" id="PayMethod" name="PayMethod" value={PayMethod || ''}/> {/*결제수단*/}
                <input type="hidden" id="ShopOrderNo" name="ShopOrderNo" value={shopOrderNo || ''}/> {/*상점 측 주문번호*/}
                <input type="hidden" id="DupliYN" name="DupliYN" value="Y"/> {/*상점 측 주문번호 중복체크 */}
                <input type="hidden" id="GoodsName" name="GoodsName" value="API_PLEX"/> {/*상품명*/}
                <input type="hidden" id="ShopID" name="ShopID" value={'CJ2205200001'} /> {/*가맹점아이디*/}
                <input type="hidden" id="UserID" name="UserID" value={userInfor.userId || ''}/> {/*사용자아이디*/}
                <input type="hidden" id="UserName" name="UserName" value={userInfor.managerName || ''}/> {/*사용자 이름*/}
                <input type="hidden" id="UserEmail" name="UserEmail" value={userInfor.email || ''}/> {/*사용자 이메일*/}
                <input type="hidden" id="AmountTotal" name="AmountTotal" value={Math.floor(amount*1.1) || ''}/> {/*결제금액*/}
                <input type="hidden" id="RedirectUrl" name="RedirectUrl" value={`${process.env.REACT_APP_API_SERVER_URL}/paymentResult`}/> {/*결제완료(화면)URL*/}
                <input type="hidden" id="CallbackUrl" name="CallbackUrl" value={`${process.env.REACT_APP_API_SERVER_URL}/api/payment/result`}/> {/*결제완료(서버)URL*/}
                <input type="hidden" id="CloseDate" name="CloseDate" value={CloseDate || ''}/>
                <input type="hidden" id="CJSHashData" name="CJSHashData" value={CJSHashData || ''}/>
                <input type="hidden" id="CardCode" name="CardCode" value={CardCode || ''}/>
                <input type="hidden" id="BankCd" name="BankCd" value={BankCd || ''}/>
                <input type="hidden" id="UserPhone " name="UserPhone" value={UserPhone || ''}/>
                <input type="hidden" id="AccountOwner" name="AccountOwner" value={AccountOwner || ''}/>
                <input type="hidden" id="CashReceiptInfo" name="CashReceiptInfo" value={CashReceiptInfo || ''}/>
                <input type="hidden" id="KeyInType" name="KeyInType" value={KeyInType || ''}/>
                <input type="hidden" id="BatchKeyType" name="BatchKeyType" value={BatchKeyType || ''}/>
                {(()=>{
                    if(selectedPaymethod == 4){
                        return(
                            <>
                                <input type="hidden" id="NvpOpenType" name="NvpOpenType" value={"popup"} />
                                <input type="hidden" id="NvpProductTotCnt" name="NvpProductTotCnt" value={1} />
                                <input type="hidden" id="NvpProductCnt" name="NvpProductCnt" value={1} />
                                <input type="hidden" id="NvpCardCode" name="NvpCardCode" value={"C0,C1,C3,C4,C5,C7,C9,CF,CH"} />
                                <input type="hidden" id="NvpCardPointYN" name="NvpCardPointYN" value={"Y"} />
                                <input type="hidden" id="NvpInstallment" name="NvpInstallment" value={6} />
                                <input type="hidden" id="NvpMinAmount" name="NvpMinAmount" value={50000} />
                                <input type="hidden" id="NvpProduct" name="NvpProduct"  value={encodeURIComponent(`"productItems":[{
                                    categoryType:"ETC", "name":"API PLEX 여신 충전", "count":1, "uid":"001","categoryId":"ETC",
                                }]` || '')}/>
                            </>
                        )
                    }
                })()}
            </form>
        </div>
    )
}

export default PayModal