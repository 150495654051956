import React, {useState, useEffect} from 'react';
import {t} from 'i18next';

const FileForm = (props) => {
    const {setReqFiles} = props;
    const [files, setFiles] = useState([]);
    const [viewFiles, setViewFiles] = useState([]);

    useEffect(()=>{
        setReqFiles(files)
    },[files])

    return(
        <>
            <input
                type="file"
                style={{display:'none'}}
                id="addFile"
                name="addFile"
                multiple="multiple"
                onClick={(e)=>{ e.target.value = '' }}
                onChange={(e)=>{
                    let fileData = new FormData();

                    for(const element of e.target.files){
                        if(element.size > 1024*1024*10){
                            alert(t('alert.alert14'));
                            return;
                        }
                        fileData.append('attachment', element , element.name);
                    }
                    setFiles(Array.from(e.target.files))
                    setViewFiles(fileData.getAll('attachment'))
                }}
            />
            <button className="btn_sm4" onClick={()=>{document.querySelector('#addFile').click();}}>{t('support.reqForm07_btn')}</button>
            <div className="file_list">
                {
                    viewFiles && viewFiles.length !== 0 &&
                        viewFiles.map((file,idx)=>{
                            return <FileItem key={idx} idx={idx} files={files} viewFiles={viewFiles} setFiles={setFiles} setViewFiles={setViewFiles}>{file.name}</FileItem>
                        })
                }
            </div>
        </>
    )
}

const FileItem = (props) => {
    const {children, files, viewFiles, setViewFiles, setFiles, idx} = props;
    return(
        <div className={`file ${idx}`}>
            {children}
            <button>
                <img
                    style={{verticalAlign: 'baseline'}}
                    src={`/images/icon_f_close.png`}
                    alt={t('alt.alt1')}
                    onClick={()=>{
                        if(files.length == 1){
                            setFiles([]); 
                            setViewFiles([]);
                        }else{
                            setFiles([...files.slice(0,idx), ...files.slice(idx+1, files.length)]);
                            setViewFiles([...viewFiles.slice(0,idx), ...viewFiles.slice(idx+1, viewFiles.length)]);
                        }
                    }}
                />
            </button>
        </div>
    )
}

export default FileForm;