import React, {useState, useEffect, useRef} from 'react';
import Loading from '../../Util/Loading';
import { cancelTemplateInspect, cancelTempltApproval, deleteTemplate, getTemplateCategory, modifyTemplateInspect, uploadImage } from '../../Util/reqApi';

const ServiceConsoleTab06_modify = (props) => {
    const {modal, setModal, modifyData, setIsChanged, setReqInspectionModal} = props;
    const [senderKeyType, setSenderKeyType] = useState('');
    const [templtCode, setTempltCode] = useState('')
    const [templtName, setTempltName] = useState('')
    const [templtMessageType, setTempltMessageType] = useState('')
    const [templtEmphasizeType, setTempltEmphasizeType] = useState('NONE')
    const [templtContent, setTempltContent] = useState('')
    const [templtExtra, setTempltExtra] = useState('');
    const [templtAd, setTempltAd] = useState('채널 추가하고 이 채널의 광고와 마케팅 메세지 받기')
    const [templtImageName, setTempltImageName] = useState('')
    const [templtImageUrl, setTempltImageUrl] = useState('')
    const [templtTitle, setTempltTitle] = useState('')
    const [templtSubtitle, setTempltSubtitle] = useState('')
    const [catCode, setCatCode] = useState('')
    const [securityFlag, setSecurityFlag] = useState(false)
    const [buttons, setButtons] = useState([])

    const [quickReplies, setQuickReplies] = useState([])
    const [cate_01, setCate_01] = useState();

    const [templateGroups, setTemplateGroups] = useState([])
    const [templateGroups02, setTemplateGroups02] = useState([])
    const [templateGroupsDetail, setTemplateGroupsDetail] = useState([])

    const inspStatusText = {REG:"등록", REQ:"심사요청", APR:"승인", REJ: "반려"}
    const statusText = {S:"중단", A:"정상", R:"대기(발송전)"}

    const [templateHeader, setTemplateHeader] = useState('')
    const [itemlist, setItemlist] = useState([{title:'', description:''},{title:'', description:''}]);
    const [itemHighlightTitle, setItemHighlightTitle] = useState('');
    const [itemHighlightDesc, setItemHighlightDesc] = useState('');
    const [itemSummaryTitle, setItemSummaryTitle] = useState('');
    const [itemSummaryDesc, setItemSummaryDesc] = useState('');
    const [itemHighlightImgName, setItemHighlightImgName] = useState('');
    const [itemHighlightImgUrl, setItemHighlightImgUrl] = useState('');

    const updateItemlist = (idx, title, description) => {
        let new_itemlist = [...itemlist];
        new_itemlist[idx].title = title;
        new_itemlist[idx].description = description;
        setItemlist(new_itemlist);
    }

    const modalClose = (e) => {
        e.preventDefault()
        setModal(false)
    }

    useEffect(()=>{
        getTemplateCategory({userId: modifyData.userId, senderKey: modifyData.senderKey}).then(result => {
            if(typeof(result) == 'string') return;

            setTemplateGroupsDetail(result.data.data.list)
            const _result = result.data.data.list
            _result.forEach((el,idx) => {
                if(idx == 0 || _result[idx].groupName !== _result[idx-1].groupName){
                    templateGroups.push(el)
                }
            })
            setTemplateGroups(templateGroups);
        }).then(()=>{
            setSecurityFlag(modifyData.securityFlag)
            setCate_01(templateGroups.find(el => {
                return el.catCode.substr(0,3) == modifyData.catCode.substr(0,3);
            }).groupName)
            modifyData.catCode && setCatCode(modifyData.catCode);
            modifyData.senderKeyType && setSenderKeyType(modifyData.senderKeyType);
            modifyData.templtCode && setTempltCode(modifyData.templtCode);
            modifyData.templtName && setTempltName(modifyData.templtName);
            modifyData.templtMessageType && setTempltMessageType(modifyData.templtMessageType);
            modifyData.templtEmphasizeType && setTempltEmphasizeType(modifyData.templtEmphasizeType);
            modifyData.templtTitle && setTempltTitle(modifyData.templtTitle);
            modifyData.templtSubtitle && setTempltSubtitle(modifyData.templtSubtitle);
            modifyData.templtContent && setTempltContent(modifyData.templtContent);
            modifyData.templtExtra && setTempltExtra(modifyData.templtExtra);
            modifyData.buttons && setButtons(modifyData.buttons);
            modifyData.quickReplies && setQuickReplies(modifyData.quickReplies);
            modifyData.templtImageName && setTempltImageName(modifyData.templtImageName)
            modifyData.templtImageUrl && setTempltImageUrl(modifyData.templtImageUrl)
            modifyData.templateHeader && setTemplateHeader(modifyData.templateHeader)
            modifyData.templateItem && modifyData.templateItem.list && setItemlist(modifyData.templateItem.list)
            modifyData.templateItemHighlight && setItemHighlightTitle(modifyData.templateItemHighlight.title)
            modifyData.templateItemHighlight && setItemHighlightDesc(modifyData.templateItemHighlight.description)
            modifyData.templateItemHighlight && modifyData.templateItemHighlight.imageUrl && setItemHighlightImgUrl(modifyData.templateItemHighlight.description.imageUrl)
            modifyData.templateItem && modifyData.templateItem.summary && setItemSummaryTitle(modifyData.templateItem.summary.title)
            modifyData.templateItem && modifyData.templateItem.summary && setItemSummaryDesc(modifyData.templateItem.summary.description)
        })
    },[])

    useEffect(()=>{
        setTemplateGroups02();
        setTemplateGroups02(templateGroupsDetail.filter( el =>{
            return el.groupName == cate_01
        }))
    },[cate_01])

    useEffect(()=>{
        if(templtMessageType == "AD" || templtMessageType == "MI"){
            setButtons([{name:"채널추가", linkType:"AC"}])
        }else{
            setButtons([])
        }
        setQuickReplies([])
    },[templtMessageType])

    if(templateGroups){
        return(
            <div id="" className="pop_set_p" style={modal ? {disply:'block'} : {display:'none'}}>
                <div className="pop_section">
                    <div className="parea">
                        <div className="page">
                            <div className="ppheader">
                                <div className="htit">템플릿 상세보기 & 수정</div>
                                <div className="rclose" onClick={(e) => {setIsChanged(false); modalClose(e)}}><img src='/images/icon_pop_close.png' alt="닫기"/></div>
                            </div>
                            <div>
                                <div className="tt_h2">템플릿 상세보기 & 수정</div>
                                <div className="s_u_p_tit3">
                                    <p>
                                    - 여러건의 템플릿을 동시등록 시에는 대량등록 기능을 이용해 주세요.(최대 1000개)<br/>
                                    - 템플릿 동시 등록은 가능하나, 수정 및 검수 요청은 템플릿 별로 진행 됩니다.<br/>
                                    - 발신 프로필 UUID를 선택해 주세요. 템플릿별 하나의 발신프로필 UUID만 선택 가능합니다. (템플릿명은 메시지 내용에 포함되지 않습니다.)<br/>
                                    - 한/영구분없이 URL 포함 1,000자 입력가능합니다. 변수에 들어갈 내용의 최대 길이를 감안하여 작성해주세요.<br/>
                                    - 변수를 '#&#123;변수&#125;' 형태로 넣어주세요. (예:#&#123;홍길동&#125;님의 택배가 금일 (#&#123;09:50&#125;)에 배달될 예정 입니다.)<br/>
                                    - 하나의 발신 프로필에 동일한 템플릿코드와 템플릿명을 중복해서 등록할 수 없습니다.<br/>
                                    </p>
                                    <br/>
                                    <p><strong>
                                    &#60;2024.04.01 기준 추가 사항&#62;<br/>
                                    메세지 유형 중 '채널 추가형'과 '복합형'의 경우, 기본적으로 포함되는 채널 추가 관련 안내 글자수&#40;약 40자&#41;를 제외하고<br/>
                                    '본문&#43;변수&#43;부가정보' 메세지 최대 글자수가 변경되었습니다.<br/>
                                    - 기본&#47;이미지 &#58; 960자<br/>
                                    - 아이템리스트 &#58; 660자
                                    </strong></p>
                                </div>
                                <div className="tab ArtT_tab">
                                </div>
                                <div id="tabTem-1" className="tab-content current">
                                    <table className="tt_tableArt">
                                        <colgroup>
                                            <col width="15%" />
                                            <col width="*" />
                                        </colgroup>
                                        <tbody>
                                            <tr>
                                                <th>발신 프로필</th>
                                                <td>
                                                    {modifyData.selectedATProfile}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>템플릿 코드</th>
                                                <td>
                                                    {modifyData.templtCode}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>보안 템플릿<br />설정</th>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        id="art1"
                                                        name="art"
                                                        onChange={(e)=>{setSecurityFlag(e.target.checked)}}
                                                        checked={securityFlag}
                                                    />
                                                        <label htmlFor="art1" style={{marginLeft: '10px', fontSize: '12px'}}>보안 템플릿 체크 시, 메인 디바이스 모바일 외 모든 서브 디바이스에서는 메시지 내용이 노출되지 않습니다.</label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>카테고리</th>
                                                <td>
                                                    <select
                                                        style={{width:"150px"}}
                                                        value={cate_01}
                                                        onChange={(e)=>{
                                                            setCate_01(e.target.value);
                                                        }}
                                                    >
                                                        <option>선택</option>
                                                        {templateGroups.map(el => {
                                                            return <option key={el.groupName} value={el.groupName}>{el.groupName}</option>
                                                        })}
                                                    </select>
                                                    <select
                                                        style={{width:"150px"}}
                                                        value={catCode}
                                                        onChange={(e)=>{setCatCode(e.target.value)}}
                                                    >
                                                        <option>선택</option>
                                                        {templateGroups02.map(el=>{
                                                            return <option key={el.catCode} value={el.catCode}>{el.catName}</option>
                                                        })}
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>템플릿명</th>
                                                <td><input
                                                    type="text"
                                                    className="ip_size200"
                                                    value={templtName}
                                                    onChange={(e)=>{setTempltName(e.target.value)}}
                                                /></td>
                                            </tr>
                                            <tr>
                                                <th>메시지 유형</th>
                                                <td>
                                                    <select
                                                        className="ip_size200"
                                                        onChange={(e)=>{setTempltMessageType(e.target.value)}}
                                                        value={templtMessageType}                                                                    
                                                    >
                                                        <option>선택</option>
                                                        <option value="BA">기본형</option>
                                                        <option value="EX">부가 정보형</option>
                                                        <option value="AD">채널 추가형</option>
                                                        <option value="MI">복합형</option>
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>강조 유형</th>
                                                <td>
                                                    <select
                                                        className="ip_size200"
                                                        onChange={(e)=>{setTempltEmphasizeType(e.target.value)}}
                                                        value={templtEmphasizeType}
                                                    >
                                                        <option value="NONE">선택안함</option>
                                                        <option value="TEXT">강조표기형</option>
                                                        <option value="IMAGE">이미지형</option>
                                                        <option value="ITEM_LIST">아이템리스트형</option>
                                                    </select>
                                                    {(() =>{ 
                                                        if(templtEmphasizeType === 'ITEM_LIST'){
                                                            return (
                                                                <span style={{marginLeft: '5px', fontSize: '10px'}}>(이미지, 헤더, 아이템하이라이트, 아이템리스트 중 1개 이상 입력)</span>
                                                            )
                                                        }
                                                    })()}
                                                </td>
                                            </tr>
                                            {(()=>{
                                                if(templtEmphasizeType === 'TEXT'){
                                                    return(
                                                        <>
                                                            <tr>
                                                                <th>강조 표기 타이틀</th>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        className="ip_size200"
                                                                        onChange={(e)=>{setTempltTitle(e.target.value)}}
                                                                        value={templtTitle}
                                                                        maxLength={50}
                                                                        style={{width:"300px"}}
                                                                        placeholder={`텍스트를 입력해주세요. (최대 50자)`}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        <tr>
                                                            <th>강조 표기 보조 문구</th>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    className="ip_size200"
                                                                    onChange={(e)=>{setTempltSubtitle(e.target.value)}}
                                                                    value={templtSubtitle}
                                                                    maxLength={50}
                                                                    style={{width:"300px"}}
                                                                    placeholder={`텍스트를 입력해주세요. (최대 50자)`}
                                                                />
                                                            </td>
                                                        </tr>
                                                    </>
                                                    )
                                                }else if(templtEmphasizeType === 'IMAGE'){
                                                    return(
                                                        <tr>
                                                            <th>이미지</th>
                                                            <td>
                                                                <ImageUpload userId={modifyData.userId} type="AT" setImgName={setTempltImageName} setImgUrl={setTempltImageUrl}/>
                                                                <div style={{maxWidth:'300px'}}>
                                                                    <img src={modifyData.templtImageUrl} style={{width:'100%'}} />
                                                                    <p>*이미지 추가하지 않을 경우 현재 이미지 적용</p>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }else if(templtEmphasizeType === 'ITEM_LIST'){
                                                    return(
                                                        <>
                                                            <tr>
                                                                <th>이미지</th>
                                                                <td>
                                                                    <ImageUpload userId={modifyData.userId} type="AT" setImgName={setTempltImageName} setImgUrl={setTempltImageUrl}/>
                                                                    <p style={{fontSize: '10px'}}>권장 사이즈 : 800px * 400px, 가로:세로 비율 2:1 (jpg, png / 최대 500KB)</p>
                                                                    <p style={{fontSize: '10px'}}>가로 500px 세로 250px 이하일 경우 / 파일명이 너무 길 경우 업로드 불가</p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>헤더</th>
                                                                <td><input
                                                                    type="text"
                                                                    className="ip_size200"
                                                                    value={templateHeader}
                                                                    onChange={(e)=>{setTemplateHeader(e.target.value)}}
                                                                    placeholder='최대 16자, 변수 입력 가능'
                                                                />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>아이템<br />하이라이트</th>
                                                                <td>
                                                                    <span style={{display: 'inline-block', marginBottom: '10px', fontSize: '12px'}}>텍스트, 디스크립션 모두 <strong>변수 입력이 가능합니다.</strong></span>
                                                                    <table className="tt_tableArt">
                                                                        <colgroup>
                                                                            <col width="55%" />
                                                                            <col width="45%" />
                                                                        </colgroup>
                                                                        <tbody>
                                                                            <tr>
                                                                                <th style={{textAlign: 'center'}}>텍스트</th>
                                                                                <th style={{textAlign: 'center'}}>디스크립션</th>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    <input 
                                                                                        type='text' 
                                                                                        style={{width: '100%'}}
                                                                                        onChange={(e) => (setItemHighlightTitle(e.target.value))}
                                                                                        value={itemHighlightTitle}
                                                                                        placeholder={itemHighlightImgName === ''? '최대 30자(2줄), 15자(1줄)' : '최대 21자(2줄, 초과시 말줄임 처리), 10자(1줄)'}
                                                                                    ></input>
                                                                                </td>
                                                                                <td>
                                                                                    <input 
                                                                                        type='text' 
                                                                                        style={{width: '100%'}}
                                                                                        onChange={(e) => (setItemHighlightDesc(e.target.value))}
                                                                                        value={itemHighlightDesc}
                                                                                        placeholder={itemHighlightImgName === ''? '최대 19자(1줄)' : '최대 13자(1줄, 초과시 말줄임 처리)'}
                                                                                    ></input>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th colSpan={2} style={{textAlign: 'center'}}>썸네일 이미지</th>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan={2}>
                                                                                    <ImageUpload userId={modifyData.userId} type="ITEM_H" setImgName={setItemHighlightImgName} setImgUrl={setItemHighlightImgUrl}/>
                                                                                    <p style={{fontSize: '10px'}}>제한 사이즈 : 가로 108px 이상, 가로:세로 비율 1:1 (jpg, png / 최대 500KB)</p>
                                                                                    <p style={{fontSize: '10px'}}>파일명이 너무 길 경우 업로드 불가</p>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>아이템리스트
                                                                    <button
                                                                        className="btn_smA2_sm"
                                                                        onClick={()=>{
                                                                            if(itemlist.length > 9){alert('아이템리스트는 최대 10개까지 생성 가능합니다.'); return}
                                                                            setItemlist([...itemlist,{title:'', description:''}])
                                                                    }}
                                                                    >
                                                                        추가</button>
                                                                </th>
                                                                <td>
                                                                    <p style={{fontSize: '12px', marginBottom: '10px'}}>아이템리스트는 <strong>최소 2개 ~ 최대 10개</strong>까지 등록 가능합니다.</p>
                                                                    <table className="table_mypageArt_sm">
                                                                        <colgroup>
                                                                            <col width="40px" />
                                                                            <col width="180px" />
                                                                            <col width="*" />
                                                                            <col width="60px" />
                                                                        </colgroup>
                                                                        <tbody>
                                                                            <tr>
                                                                                <th style={{textAlign: 'center'}}>no</th>
                                                                                <th style={{textAlign: 'center'}}>아이템명</th>
                                                                                <th style={{textAlign: 'center'}}>아이템 내용</th>
                                                                                <th style={{textAlign: 'center'}}></th>
                                                                            </tr>
                                                                            {(()=>{
                                                                                if(itemlist.length == 0) return <tr><td colSpan={5} style={{textAlign:"center"}}>아이템리스트를 추가할 수 있습니다.</td></tr>                                                                            
                                                                                return itemlist && itemlist.map((item, idx) => {
                                                                                    return(
                                                                                        <tr key={idx}>
                                                                                            <td style={{textAlign: 'center'}}>{idx+1}</td>
                                                                                            <td>
                                                                                                <input 
                                                                                                    value={item.title || ''} 
                                                                                                    onChange={(e) => {updateItemlist(idx, e.target.value, item.description);}} 
                                                                                                    style={{width: '100%'}}
                                                                                                    placeholder='최대 6자(변수사용 불가)'
                                                                                                ></input>
                                                                                            </td>
                                                                                            <td>
                                                                                                <input 
                                                                                                    value={item.description || ''} 
                                                                                                    onChange={(e) => {updateItemlist(idx, item.title, e.target.value)}} 
                                                                                                    style={{width: '100%'}}
                                                                                                    placeholder='최대 23자(2줄 초과시 말줄임처리)'
                                                                                                ></input>
                                                                                                </td>
                                                                                            <td>
                                                                                                {idx > 1 &&
                                                                                                    <button
                                                                                                    className="btn_smA2_sm"
                                                                                                    onClick={()=>{
                                                                                                        setItemlist([...itemlist.filter((_,_idx) => idx !== _idx)])
                                                                                                    }}
                                                                                                    >
                                                                                                    삭제
                                                                                                    </button>
                                                                                                }

                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                })
                                                                            })()}
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>아이템<br />요약정보</th>
                                                                <td>
                                                                    <p style={{fontSize: '10px', marginBottom: '10px'}}>(아이템 내용 요약은 통화기호 또는 통화단위, 변수 를 포함한 숫자, comma(쉼표), perio(마침표) 조합만 가능합니다.)</p>
                                                                    <input 
                                                                        type='text' 
                                                                        style={{width:'25%', marginRight:'2%'}} 
                                                                        value={itemSummaryTitle}
                                                                        onChange={(e) => {setItemSummaryTitle(e.target.value)}}
                                                                        placeholder='최대 6자'
                                                                    ></input>
                                                                    <input 
                                                                        type='text' 
                                                                        style={{width:'45%'}} 
                                                                        value={itemSummaryDesc}
                                                                        onChange={(e) => {setItemSummaryDesc(e.target.value)}}
                                                                        placeholder='14자 이내'
                                                                    ></input>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    )
                                                }
                                            })()}
                                            <tr>
                                                <th>등록일</th>
                                                <td>
                                                    {modifyData.cdate}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>검수상태</th>
                                                <td>
                                                    {inspStatusText[modifyData.inspStatus]}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>템플릿상태</th>
                                                <td>
                                                    {statusText[modifyData.status]}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>템플릿내용</th>
                                                <td>
                                                    <textarea
                                                        style={{width:"90%",height:"80px"}}
                                                        onChange={(e)=>{setTempltContent(e.target.value)}}
                                                        value={templtContent}
                                                    >
                                                    </textarea>
                                                    {(()=>{if(templtMessageType === 'AD'){
                                                        return(
                                                            <p>
                                                                ※알림<br />
                                                                <br />
                                                                카카오 측 권고사항으로 '채널 추가형' 버튼이 기본형으로 노출되고 있습니다.<br />
                                                                '채널추가형' 버튼 생성을 원치 않는 사용자는 '채널 추가형'에서 '기본형'으로 변경하여 이용 부탁드립니다.<br />
                                                                <br />
                                                                또한 법적으로 이용자에게 필수 안내가 되어야 하는 휴면 전환 예정안내 메시지의 경우,<br />
                                                                기본형으로 검수 등록 부탁드립니다.<br />
                                                                <br />
                                                                해당 안내는 일반 등록 경우에 해당됩니다.<br />
                                                                <br />
                                                                * 대량 등록은 기존과 동일하게 진행하여 주시면 됩니다.<br />
                                                                <br />
                                                                감사합니다.
                                                            </p>
                                                        )
                                                    }})()}
                                                </td>
                                            </tr>
                                            {(()=>{
                                                if(templtMessageType === 'EX' || templtMessageType === 'MI'){
                                                    return(
                                                        <tr>
                                                            <th>부가 정보</th>
                                                            <td>
                                                                <textarea
                                                                    style={{width:"90%",height:"80px"}}
                                                                    onChange={(e)=>{setTempltExtra(e.target.value)}}
                                                                    value={templtExtra}
                                                                >
                                                                </textarea>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            })()}
                                            {(()=>{
                                                if(templtMessageType === 'AD' || templtMessageType === 'MI'){
                                                    return(
                                                        <tr>
                                                            <th>
                                                                광고성 메시지<br />
                                                                (수신 동의 요청 또는 간단 광고 문구)
                                                            </th>
                                                            <td>
                                                                <textarea
                                                                    style={{width:"90%",height:"80px"}}
                                                                    onChange={(e)=>{setTempltAd(e.target.value)}}
                                                                    readOnly={true}
                                                                    value={templtAd || "채널 추가하고 이 채널의 광고와 마케팅 메세지 받기"}
                                                                >
                                                                </textarea>
                                                                <p>※카카오정책에 따라 해당 문구는 변경불가</p>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            })()}
                                            <tr>
                                                <th>버튼<br />
                                                    <button
                                                        className="btn_smA2_sm"
                                                        onClick={()=>{
                                                            if(buttons.length >= 5){alert('버튼은 최대 5개까지 생성 가능합니다.'); return}
                                                            if(buttons.length >= 2 && quickReplies.length >= 1){alert('바로연결과 함께 사용 시 버튼은 최대 2개까지 생성 가능합니다.'); return}
                                                            setButtons([...buttons,{}])
                                                        }}
                                                    >추가</button>
                                                </th>
                                                <td>
                                                    <table className="table_mypageArt_sm">
                                                        <colgroup>
                                                            <col width="30px" />
                                                            <col width="130px" />
                                                            <col width="130px" />
                                                            <col width="*" />
                                                            <col width="60px" />
                                                        </colgroup>
                                                        <thead>
                                                            <tr>
                                                                <th>no</th>
                                                                <th>버튼타입</th>
                                                                <th>버튼명</th>
                                                                <th>버튼링크</th>
                                                                <th>삭제</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {(()=>{
                                                                if(buttons.length === 0) return <tr><td colSpan={5} style={{textAlign:"center"}}>버튼을 추가할 수 있습니다.</td></tr>
                                                                return buttons.map((_, idx) => {
                                                                    return(
                                                                        <ButtonRow
                                                                            key={idx}
                                                                            idx={idx}
                                                                            buttons={buttons}
                                                                            setButtons={setButtons}
                                                                            templtMessageType={templtMessageType}
                                                                        />
                                                                    )
                                                                })
                                                            })()}
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>바로연결&nbsp;
                                                    <button
                                                        className="btn_smA2_sm"
                                                        onClick={()=>{
                                                            if(quickReplies.length >= 10){alert('바로연결은 최대 10개까지 생성 가능합니다.'); return}
                                                            if(buttons.length > 2){if(window.confirm('바로 연결 사용시, 버튼은 2개만 등록가능합니다.\n계속 하시려면 [확인] 선택, 취소하시려면 [취소]를 선택하세요.\n[확인]을 누르면 1,2번 버튼을 제외한 버튼은 삭제됩니다.')){setButtons([...buttons.slice(0,2)])}else{return false;}}
                                                            setQuickReplies([...quickReplies,{}])
                                                        }}
                                                    >추가</button>
                                                </th>
                                                <td>
                                                    <table className="table_mypageArt_sm">
                                                        <colgroup>
                                                            <col width="30px" />
                                                            <col width="130px" />
                                                            <col width="130px" />
                                                            <col width="*" />
                                                            <col width="60px" />
                                                        </colgroup>
                                                        <thead>
                                                            <tr>
                                                                <th>no</th>
                                                                <th>바로연결타입</th>
                                                                <th>바로연결명</th>
                                                                <th>바로연결링크</th>
                                                                <th>삭제</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {(()=>{
                                                                if(quickReplies.length == 0) return <tr><td colSpan={5} style={{textAlign:"center"}}>바로연결을 추가할 수 있습니다.</td></tr>                                                                            
                                                                return quickReplies.map((_, idx) => {
                                                                    return(
                                                                        <QuickLinkRow
                                                                            key={idx}
                                                                            idx={idx}
                                                                            quickReplies={quickReplies}
                                                                            setQuickReplies={setQuickReplies}
                                                                        />
                                                                    )
                                                                })
                                                            })()}
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>비고</th>
                                                <td>
                                                    <ul>
                                                        <li style={{marginBottom:'10px'}}>템플릿상태가 대기(R)이고, <br />템플릿 검수상태가 등록(REG) 또는 반려(REJ)인 경우에만 수정 가능합니다.</li>
                                                        <li>템플릿상태가 대기(R)이고, <br />템플릿 검수상태가 승인(APR) 이 아닌 경우에만 삭제 가능합니다.</li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="tt_blank20"></div>
                            {/* 
                                Button 종류 (2024.07.08 기준 4개)

                                    검수 상태 : 등록(REG) / 심사요청(REQ) / 승인(APR) / 반려(REJ)    ( 검수 상태가 승인(APR) 일 때만 발송할 수 있음 )
                                    상태 : 중단(S) / 정상(A) / 대기(R)                              ( 상태가 정상(A) 일 때만 발송할 수 있음 )

                                1. 템플릿 삭제 버튼
                                    1) 활성화
                                        - 검수 상태가 승인(APR) 이 아니고
                                        - 상태가 대기(R) 인 경우

                                    2) 삭제 불가능시 
                                        - 회색으로 비활성화되고
                                        - 버튼명은 그대로

                                2. 템플릿 임시저장(수정) 버튼
                                    1) 활성화
                                        - 검수 상태가 등록(REG) 또는 반려(REJ) 이고
                                        - 상태가 대기(R) 인 경우

                                    2) 임시저장 불가능할 경우 
                                        - 회색으로 비활성화되고
                                        - 버튼명은 그대로

                                3. 템플릿 검수요청/검수요청취소 버튼
                                    1) "검수요청" 활성화
                                        - 검수 상태가 등록(REG) 또는 반려(REJ) 이고
                                        - 상태가 대기(R) 인 경우

                                    2) "검수요청취소" 활성화
                                        - 검수 상태가 심사요청(REQ) 이고
                                        - 상태가 대기(R) 인 경우
                                        - 버튼명이 "검수요청취소"로 변경됨
                                        - 버튼 색이 변경됨

                                    3) "검수완료"로 비활성화
                                        - 검수 상태가 승인(APR) 일 경우
                                        - 회색으로 비활성화되고
                                        - 버튼명이 "검수완료" 로 변경

                                4. 템플릿 승인취소 버튼
                                    1) 활성화
                                        - 검수 상태가 승인(APR)이고
                                        - 상태가 대기(R) 인 경우
                                    
                                    2) 승인취소가 불가능할 경우
                                        - 회색으로 비활성화
                                        - 버튼명 그대로
                            */}
                            <div className="nt_btn_area_p">
                                {/* 삭제버튼 */}
                                <button
                                    className={modifyData.status === 'R' && modifyData.inspStatus !== 'APR' ? "btn_sm6_red" : "btn_sm6_disabled"}
                                    disabled={!(modifyData.status === 'R' && modifyData.inspStatus !== 'APR')}
                                    onClick={()=>{
                                        if(modifyData.status === 'R' && modifyData.inspStatus !== 'APR'){
                                            deleteTemplate({
                                                senderKeyType: senderKeyType,
                                                templtCode: modifyData.templtCode,
                                                senderKey: modifyData.senderKey,
                                                userId: modifyData.userId
                                            }).then(result=>{
                                                if(result.data.code === '200'){
                                                    alert('템플릿이 삭제되었습니다.')
                                                    setIsChanged(true);
                                                    setModal(false);
                                                }else{
                                                    alert(result.data.message);
                                                }
                                            })
                                        }else{
                                            alert('현재 삭제가 불가능한 상태입니다.')
                                        }
                                    }}
                                >삭제</button>
                                {/* 임시저장 버튼 */}
                                <button
                                    className={modifyData.status === 'R' && (modifyData.inspStatus === 'REG' || modifyData.inspStatus === 'REJ') ? "btn_sm6": "btn_sm6_disabled"}
                                    disabled={!(modifyData.status === 'R' && (modifyData.inspStatus === 'REG' || modifyData.inspStatus ==='REJ'))}
                                    onClick={(e)=>{
                                                modifyTemplateInspect({
                                                    userId : modifyData.userId,
                                                    senderKey : modifyData.senderKey,
                                                    senderKeyType : senderKeyType,
                                                    templtCode : templtCode,
                                                    templtName : templtName,
                                                    templtMessageType : templtMessageType,
                                                    templtEmphasizeType : templtEmphasizeType,
                                                    templtContent : templtContent,
                                                    templtExtra : templtExtra,
                                                    templtAd : templtAd,
                                                    templtImageName : templtImageName,
                                                    templtImageUrl : templtImageUrl,
                                                    templtTitle : templtTitle,
                                                    templtSubtitle : templtSubtitle,
                                                    catCode : catCode,
                                                    securityFlag : securityFlag,
                                                    buttons : buttons,
                                                    quickReplies : quickReplies,
                                                    templateHeader : templateHeader,
                                                    templateItemHighlight : {
                                                        title : itemHighlightTitle,
                                                        description : itemHighlightDesc,
                                                        imageUrl : itemHighlightImgUrl
                                                    },
                                                    templateItem : {
                                                        list : itemlist,
                                                        summary: {
                                                            title : itemSummaryTitle,
                                                            description : itemSummaryDesc                                    
                                                        }                                                    
                                                    }
                                                }).then(result => {
                                                        if(result.data.code !== '200'){
                                                            if(typeof(result.data.message) !== 'string'){
                                                                alert('에러가 발생하였습니다.\n관리자에게 문의해주세요.')
                                                            }else{
                                                                alert(result.data.message)
                                                            }
                                                        }else{
                                                            alert('정상적으로 수정되었습니다.')
                                                            setIsChanged(true)
                                                            modalClose(e)
                                                        }
                                            })}
                                }>임시저장</button>
                                {/* 검수요청/검수요청취소 버튼 */}
                                <button className={modifyData.status === 'R' && (modifyData.inspStatus === 'REG' || modifyData.inspStatus === 'REQ') ?
                                                             "btn_sm6_green" : "btn_sm6_disabled" }
                                        disabled={(modifyData.status !== 'R') || modifyData.inspStatus === 'APR' || modifyData.inspStatus === 'REJ'}
                                        onClick={(e)=>{
                                            if(modifyData.status === 'R' && modifyData.inspStatus === 'REG'){
                                                setReqInspectionModal(true);
                                            }else if(modifyData.status === 'R' && modifyData.inspStatus === 'REQ'){
                                                cancelTemplateInspect({
                                                        userId: modifyData.userId,
                                                        senderKey: modifyData.senderKey,
                                                        templtCode: modifyData.templtCode,
                                                        senderKeyType: senderKeyType,
                                                }).then(result => {
                                                    if(result.data.code === '200'){
                                                        alert('정상적으로 검수요청이 취소 되었습니다.')
                                                        setIsChanged(true)
                                                        modalClose(e)
                                                    }else{
                                                        alert('검수요청 취소에 실패하였습니다. 관리자에게 문의해주세요.')
                                                    }
                                                })
                                            }else {
                                                //Do nothing
                                            }
                                        }}>{modifyData.status === 'R' && (modifyData.inspStatus === 'REG') ?
                                                '검수요청' : ((modifyData.status === 'R' && modifyData.inspStatus === 'REQ' ?
                                                    '검수요청 취소' : (modifyData.inspStatus === 'APR' ?
                                                        "검수완료" : (modifyData.status === 'S') ?
                                                            "검수요청 불가(템플릿이 '중지'상태)" : (modifyData.inspStatus === 'REJ') ?
                                                                "검수요청 불가(템플릿이 '반려'상태)" : "검수요청 불가(미승인 템플릿 '정상'상태)"))
                                                )}</button>
                                {/* 승인취소 버튼 */}
                                <button className={modifyData.status === 'R' && modifyData.inspStatus === 'APR' ? 'btn_sm6_yellow' : 'btn_sm6_disabled'}
                                        disabled={!(modifyData.status === 'R' && modifyData.inspStatus === 'APR')}
                                        onClick={(e) => {
                                            cancelTempltApproval({
                                                userId: modifyData.userId,
                                                senderKey: modifyData.senderKey,
                                                templtCode: modifyData.templtCode,
                                                senderKeyType: senderKeyType
                                            }).then(result => {
                                                if(result.data.code === '200'){
                                                    alert('정상적으로 승인 취소 되었습니다.')
                                                    setIsChanged(true)
                                                    modalClose(e)
                                                }else{
                                                    alert('승인 취소 요청에 실패하였습니다. 관리자에게 문의해주세요.')
                                                }
                                            })
                                        }}>
                                승인취소</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        )
    }else return <div id="" className="pop_set_p"><div className="pop_section"><div className="parea"><div className="page"><Loading /></div></div></div></div>
}

const ButtonRow = ({idx, buttons, setButtons, templtMessageType}) => {

    const btnMsg = [
        {code : "AC", msg : `버튼명은 "채널 추가"로 고정됩니다.`},
        {code : "DS", msg : "* 알림톡 메시지 파싱을 통해 배송조회 카카오검색 페이지 링크가 자동 생성됩니다."},
        {code : "WL"},
        {code : "AL"},
        {code : "BK", msg : "* 버튼 텍스트가 상담원에게 전달됩니다."},
        {code : "MD", msg : "* 버튼 텍스트와 메시지 본문이 상담원에게 전달됩니다."},
    ]

    if(templtMessageType === "AD" || templtMessageType === "MI"){
        if(idx === 0){
            return(
                <tr>
                    <td>{idx+1}</td>
                    <td>
                        <select disabled={true} value={buttons[idx].linkType} style={{background:"#ececec"}}>
                            <option value="AC">채널추가</option>
                        </select>
                    </td>
                    <td>
                        <input
                            type="text"
                            className="ip_size120"
                            placeholder="최대 14자"
                            maxLength={14}
                            value={buttons[idx].name}
                            readOnly={true}
                            disabled={true}
                            style={{color:'#888', background:"#ececec"}}
                        />
                    </td>
                    <td>{btnMsg.find(el=> el.code === buttons[idx].linkType).msg}</td>
                    <td></td>
                </tr>
            )
        }
    }
    return (
        <tr>
            <td>{idx+1}</td>
            <td>
            <select
                className="ip_size100"
                value={buttons[idx].linkType || 'default'}
                onChange={(e)=>{
                    setButtons(
                        buttons.map((el,_idx)=>{
                            if(idx === _idx){
                                el.linkType = e.target.value;
                                el.linkA = null;
                                el.linkI = null;
                                el.linkM = null;
                                el.linkP = null;
                            }
                            return el;
                        })
                    )
                }}
            >
                <option value={"default"}>선택</option>
                <option value={'DS'}>배송조회</option>
                <option value={"WL"}>웹링크</option>
                <option value={"AL"}>앱링크</option>
                <option value={"BK"}>봇키워드</option>
{/*                <option value={"MD"}>메시지전달</option>*/}
            </select>   
            </td>
            <td>
                <input
                    type="text"
                    className="ip_size120"
                    placeholder="최대 14자"
                    maxLength={14}
                    value={buttons[idx].name || ''}
                    onChange={(e)=>{
                        setButtons(
                            buttons.map((el,_idx)=>{
                                if(idx === _idx){el.name = e.target.value;}
                                return el;
                            })
                        )
                    }}
                />
            </td>
            <td>{(()=>{
                if(!buttons[idx].linkType || buttons[idx].linkType === 'default'){return "버튼타입을 선택하세요."}
                if(buttons[idx].linkType === "WL"){
                    return(
                        <>
                            <div style={{display:'flex', alignItems:'center', marginBottom:'5px'}}>
                                <p style={{minWidth:"50px", marginRight:'5px'}}>Mobile</p>
                                <input
                                    type="text"
                                    className="ip_size120"
                                    value={buttons[idx].linkM || ''}
                                    onChange={(e)=>{
                                        setButtons(
                                            buttons.map((el,_idx)=>{
                                                if(idx === _idx){el.linkM = e.target.value;}
                                                return el;
                                            })
                                        )
                                    }}
                                />
                            </div>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <p style={{minWidth:"50px", marginRight:'5px'}}>PC(선택)</p>
                                <input
                                    type="text"
                                    className="ip_size120"
                                    value={buttons[idx].linkP || ''}
                                    onChange={(e)=>{
                                        setButtons(
                                            buttons.map((el,_idx)=>{
                                                if(idx === _idx){el.linkP = e.target.value;}
                                                return el;
                                            })
                                        )
                                    }}
                                />
                            </div>
                        </>
                    )
                };
                if(buttons[idx].linkType === "AL"){
                    return(
                        <>
                            <div style={{display:'flex', alignItems:'center', marginBottom:'5px'}}>
                                <p style={{minWidth:"50px", marginRight:'5px'}}>Android</p>
                                <input
                                    type="text"
                                    className="ip_size120"
                                    value={buttons[idx].linkA || ''}
                                    onChange={(e)=>{
                                        setButtons(
                                            buttons.map((el,_idx)=>{
                                                if(idx === _idx){el.linkA = e.target.value;}
                                                return el;
                                            })
                                        )
                                    }}
                                />
                            </div>
                            <div style={{display:'flex', alignItems:'center', marginBottom:'5px'}}>
                                <p style={{minWidth:"50px", marginRight:'5px'}}>iOS(선택)</p>
                                <input
                                    type="text"
                                    className="ip_size120"
                                    value={buttons[idx].linkI || ''}
                                    onChange={(e)=>{
                                        setButtons(
                                            buttons.map((el,_idx)=>{
                                                if(idx === _idx){el.linkI = e.target.value;}
                                                return el;
                                            })
                                        )
                                    }}
                                />
                            </div>
                            <div style={{display:'flex', alignItems:'center', marginBottom:'5px'}}>
                                <p style={{minWidth:"50px", marginRight:'5px'}}>Mobile</p>
                                <input
                                    type="text"
                                    className="ip_size120"
                                    value={buttons[idx].linkM || ''}
                                    onChange={(e)=>{
                                        setButtons(
                                            buttons.map((el,_idx)=>{
                                                if(idx === _idx){el.linkM = e.target.value;}
                                                return el;
                                            })
                                        )
                                    }}
                                />
                            </div>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <p style={{minWidth:"50px", marginRight:'5px'}}>PC(선택)</p>
                                <input
                                    type="text"
                                    className="ip_size120"
                                    value={buttons[idx].linkP || ''}
                                    onChange={(e)=>{
                                        setButtons(
                                            buttons.map((el,_idx)=>{
                                                if(idx === _idx){el.linkP = e.target.value;}
                                                return el;
                                            })
                                        )
                                    }}
                                />
                            </div>
                        </>
                    )
                }
                return btnMsg.find(el => el.code === buttons[idx].linkType).msg
            })()}</td>
            <td>
                <button
                    className="btn_smA2_sm"
                    onClick={()=>{
                        setButtons([...buttons.filter((_,_idx) => idx !== _idx)])
                    }}
                >
                    삭제
                </button>
            </td>
        </tr>
    )
}

const QuickLinkRow = ({idx, quickReplies, setQuickReplies}) => {
    const qlinkMsg = [
        {code : "WL"},
        {code : "AL"},
        {code : "BK", msg : "* 바로연결 텍스트가 상담원에게 전달됩니다."},
        {code : "MD", msg : "* 바로연결 텍스트와 메시지 본문이 상담원에게 전달됩니다."},
    ]

    return(
        <tr>
            <td>{idx+1}</td>
            <td>
            <select
                className="ip_size100"
                value={quickReplies[idx].linkType || 'default'}
                onChange={(e)=>{
                    setQuickReplies(
                        quickReplies.map((el,_idx)=>{
                            if(idx === _idx){
                                el.linkType = e.target.value;
                                el.linkA = null;
                                el.linkI = null;
                                el.linkM = null;
                                el.linkP = null;
                            }
                            return el;
                        })
                    )
                }}
            >
                <option value={"default"}>선택</option>
                <option value={"WL"}>웹링크</option>
                <option value={"AL"}>앱링크</option>
                <option value={"BK"}>봇키워드</option>
{/*                <option value={"MD"}>메시지전달</option>*/}
            </select>   
            </td>
            <td>
                <input
                    type="text"
                    className="ip_size120"
                    placeholder="최대 14자"
                    maxLength={14}
                    value={quickReplies[idx].name || ''}
                    onChange={(e)=>{
                        setQuickReplies(
                            quickReplies.map((el,_idx)=>{
                                if(idx === _idx){el.name = e.target.value;}
                                return el;
                            })
                        )
                    }}
                />
            </td>
            <td>{(()=>{
                if(!quickReplies[idx].linkType || quickReplies[idx].linkType === 'default'){return "버튼타입을 선택하세요."}
                if(quickReplies[idx].linkType === "WL"){
                    return(
                        <>
                            <div style={{display:'flex', alignItems:'center', marginBottom:'5px'}}>
                                <p style={{minWidth:"50px", marginRight:'5px'}}>Mobile</p>
                                <input
                                    type="text"
                                    className="ip_size120"
                                    value={quickReplies[idx].linkM || ''}
                                    onChange={(e)=>{
                                        setQuickReplies(
                                            quickReplies.map((el,_idx)=>{
                                                if(idx === _idx){el.linkM = e.target.value;}
                                                return el;
                                            })
                                        )
                                    }}
                                />
                            </div>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <p style={{minWidth:"50px", marginRight:'5px'}}>PC(선택)</p>
                                <input
                                    type="text"
                                    className="ip_size120"
                                    value={quickReplies[idx].linkP || ''}
                                    onChange={(e)=>{
                                        setQuickReplies(
                                            quickReplies.map((el,_idx)=>{
                                                if(idx === _idx){el.linkP = e.target.value;}
                                                return el;
                                            })
                                        )
                                    }}
                                />
                            </div>
                        </>
                    )
                };
                if(quickReplies[idx].linkType === "AL"){
                    return(
                        <>
                            <div style={{display:'flex', alignItems:'center', marginBottom:'5px'}}>
                                <p style={{minWidth:"50px", marginRight:'5px'}}>Android</p>
                                <input
                                    type="text"
                                    className="ip_size120"
                                    value={quickReplies[idx].linkA || ''}
                                    onChange={(e)=>{
                                        setQuickReplies(
                                            quickReplies.map((el,_idx)=>{
                                                if(idx === _idx){el.linkA = e.target.value;}
                                                return el;
                                            })
                                        )
                                    }}
                                />
                            </div>
                            <div style={{display:'flex', alignItems:'center', marginBottom:'5px'}}>
                                <p style={{minWidth:"50px", marginRight:'5px'}}>iOS(선택)</p>
                                <input
                                    type="text"
                                    className="ip_size120"
                                    value={quickReplies[idx].linkI || ''}
                                    onChange={(e)=>{
                                        setQuickReplies(
                                            quickReplies.map((el,_idx)=>{
                                                if(idx === _idx){el.linkI = e.target.value;}
                                                return el;
                                            })
                                        )
                                    }}
                                />
                            </div>
                            <div style={{display:'flex', alignItems:'center', marginBottom:'5px'}}>
                                <p style={{minWidth:"50px", marginRight:'5px'}}>Mobile</p>
                                <input
                                    type="text"
                                    className="ip_size120"
                                    value={quickReplies[idx].linkM || ''}
                                    onChange={(e)=>{
                                        setQuickReplies(
                                            quickReplies.map((el,_idx)=>{
                                                if(idx === _idx){el.linkM = e.target.value;}
                                                return el;
                                            })
                                        )
                                    }}
                                />
                            </div>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <p style={{minWidth:"50px", marginRight:'5px'}}>PC(선택)</p>
                                <input
                                    type="text"
                                    className="ip_size120"
                                    value={quickReplies[idx].linkP || ''}
                                    onChange={(e)=>{
                                        setQuickReplies(
                                            quickReplies.map((el,_idx)=>{
                                                if(idx === _idx){el.linkP = e.target.value;}
                                                return el;
                                            })
                                        )
                                    }}
                                />
                            </div>
                        </>
                    )
                }
                return qlinkMsg.find(el => el.code == quickReplies[idx].linkType).msg
            })()}</td>
            <td>
                <button
                    className="btn_smA2_sm"
                    onClick={()=>{
                        setQuickReplies([...quickReplies.filter((_,_idx) => idx !== _idx)])
                    }}
                >
                    삭제
                </button>
            </td>
        </tr>
    )
}

const ImageUpload = ({userId, setImgName, setImgUrl, type}) => {
    const [imageFile, setImageFile] = useState('');
    const [dataFile, setDataFile] = useState('');
    const nameInput = useRef();
    return(
        <>
            <input
                type="file"
                style={{display:'none'}}
                ref = {nameInput}
                onChange={(e)=>{
                        setDataFile(e.target.files)
                        setImageFile(e.target.value)
                    }
                }
            />
            <input
                type = "text"
                style={{width: '60%'}}
                value={imageFile}
                readOnly={true}
                onClick={()=>{
                    nameInput.current.click();
                }}
            />
            <button className="btn_smA2_sm"
                onClick={()=>{
                    nameInput.current.click();
                }}
            >찾기</button>
            <button className="btn_smA2_sm"
                onClick={()=>{
                    uploadImage({image:dataFile[0], type:type, userId: userId}).then(result=>{
                        if(result.code == 0){
                            alert(`${result.data.data.name} 등록완료`)
                            setImgName(result.data.data.name);
                            setImgUrl(result.data.data.url);
                        }else{
                            alert(result.data)
                        }
                    });
                }}
            >등록</button>
            <button 
                className="btn_smA2_sm" 
                onClick={()=>{
                    setImgName('')
                    setImgUrl('')
                    setDataFile('')
                    setImageFile('')
                }
            }>삭제</button>
        </>
    )
}

export default ServiceConsoleTab06_modify;