import React, {useState, useEffect} from 'react';
import Loading from '../../../../Util/Loading';
import { getATTemplateListPaging } from '../../../../Util/reqApi';
import Pagination from '../Pagination';


const LoadPreviousTemplate = (props) => {
    const {modal, setModal, alimProfile, setDataLoad, userId} = props;
    const {setSenderKey, 
        setCatCode, 
        setTempltMessageType, 
        setTempltEmphasizeType, 
        setTempltContent, 
        setButtons, 
        setQuickReplies, 
        setTempltTitle, 
        setTempltSubtitle, 
        setTemplateHeader, 
        setItemHighlightTitle, 
        setItemHighlightDesc, 
        setItemlist, 
        setItemSummaryTitle, 
        setItemSummaryDesc, 
        setTempltExtra,
        setTempltAd,
        templateGroupsDetail,
        setSelectedMainCate,
        } = props;  
    const [selected, setSelected] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [keyword, setKeyword] = useState('');
    const [searchResult, setSearchResult] = useState(null);

    const [searchType, setSearchType] = useState(1);
    const [pageNum, setPageNum] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRecord, setTotalRecord] = useState(0);
    const pageSize = 10;

    const [lastParams, setLastParams] = useState({});

    const initializeInputs = () => {
        if(Object.keys(lastParams).length === 0){
            setKeyword('');
            setSearchType(1);
        }else{
            setKeyword(lastParams.keyword);
            setSearchType(lastParams.searchType);
        }
    }

    const searchTemplt = () => {
        setPageNum(1);

        setLastParams({
            keyword: keyword,
            searchType: searchType
        });
    }

    const selectRow = (idx) => {
        if(selected){
            if(idx === selectedRow){
                setSelected(false);
                setSelectedRow(null);
            }else{
                setSelectedRow(idx);
            }
        }else{
            setSelectedRow(idx);
            setSelected(true);
        }
    }

    const loadTemplt = () => {
        setDataLoad(true);

        if(selectedRow === null) return;
        const selectedRowData = searchResult[selectedRow];

        setSenderKey(selectedRowData.senderKey);    //필수값

        if(!templateGroupsDetail || templateGroupsDetail.length === 0)  return;

        const selectedGroup = templateGroupsDetail.find(el => el.catCode === selectedRowData.templateData.catCode);

        setSelectedMainCate(selectedGroup.groupName);       //필수값
        setCatCode(selectedRowData.templateData.catCode);   //필수값

        setTempltMessageType(selectedRowData.templateData.templtMessageType);       //필수값
        setTempltEmphasizeType(selectedRowData.templateData.templtEmphasizeType);   //필수값

        setTempltContent(selectedRowData.templateData.templtContent || "");
        setButtons(selectedRowData.templateData.buttons || []);
        setQuickReplies(selectedRowData.templateData.quickReplies || []);

        if(selectedRowData.templateData.templtEmphasizeType === 'TEXT') {
            setTempltTitle(selectedRowData.templateData.templtTitle);
            setTempltSubtitle(selectedRowData.templateData.templtSubtitle);
        } else {
            setTempltTitle('');
            setTempltSubtitle('');
        }

        if(selectedRowData.templateData.templtEmphasizeType === 'ITEM_LIST'){
            setTemplateHeader(selectedRowData.templateData.templateHeader || "");
            if(selectedRowData.templateData.templateItemHighlight)
            {
                setItemHighlightTitle(selectedRowData.templateData.templateItemHighlight.title);
                setItemHighlightDesc(selectedRowData.templateData.templateItemHighlight.description);
            }else{
                setItemHighlightTitle('');
                setItemHighlightDesc('');
            }

            if(selectedRowData.templateData.templateItem){
                setItemlist(selectedRowData.templateData.templateItem.list || []);
                if(selectedRowData.templateData.templateItem.summary){
                    setItemSummaryTitle(selectedRowData.templateData.templateItem.summary.title);
                    setItemSummaryDesc(selectedRowData.templateData.templateItem.summary.description);
                }else{
                    setItemSummaryTitle('');
                    setItemSummaryDesc('');
                }
            }else{
                setItemlist([]);
                setItemSummaryTitle('');
                setItemSummaryDesc('');
            }
        }else {
            setTemplateHeader('');
            setItemHighlightTitle('');
            setItemHighlightDesc('');
            setItemlist([]);
            setItemSummaryTitle('');
            setItemSummaryDesc('');
        }

        if(['EX', 'MI'].includes(selectedRowData.templateData.templtMessageType)) {
            setTempltExtra(selectedRowData.templateData.templtExtra || "");
            setTempltAd(selectedRowData.templateData.templtAd || "");
        }
    }

    useEffect(() => {
        if(modal){
            setLastParams({
                keyword:'',
                searchType:1
            })
        }else{
            setSelected(false);
            setSelectedRow(null);
            setPageNum(1);
            setTotalPages(1);

            setKeyword('');
            setSearchType(1);
        }
    },[modal])

    useEffect(() => {
        if(modal){
            initializeInputs();

            setSearchResult(null);

            if(!alimProfile || alimProfile.length === 0) return;

            let senderKeys = [];
            for(const alimData of alimProfile){
                senderKeys.push(alimData.senderKey);
            }

            getATTemplateListPaging({
                senderKeys : senderKeys.toString(),
                templtName: lastParams.searchType === 1?lastParams.keyword:null,
                templtCode: lastParams.searchType === 2?lastParams.keyword:null,
                userId: userId, 
                pageNum: pageNum===0?0:pageNum-1,
                pageSize: pageSize
            })
            .then(result => {
                if(result.code === 0){
                    setTotalPages(((result.record + pageSize - 1) / pageSize) | 0);
                    setTotalRecord(result.record);
                    setSearchResult(result.data);
    
                    setSelected(false);
                    setSelectedRow(null);
                }
            }).catch(err => {
                console.error(err);
            });
        }
    },[pageNum, lastParams]);
    
    if(modal) {
        return(
            <div id="modal_load_template" className='pop_set_p' style={modal ? {disply:'block'} : {display:'none'}}>
                <div className="pop_section">
                    <div className="parea" style={{maxWidth: '1200px'}}>
                        <div className="page">
                            <div className="ppheader">
                                <div className="htit">기존 템플릿 불러오기</div>
                                <div className="rclose" onClick={() => setModal(false)}><img src='/images/icon_pop_close.png' alt="닫기"/></div>
                            </div>
                            {(() => {
                                if(searchResult){
                                    return(
                                        <>
                                        <div>
                                            <div>
                                                <select 
                                                    value={searchType}
                                                    onChange={(e) => {setSearchType(Number(e.target.value))}}
                                                    style={{marginRight:'10px'}}
                                                >
                                                    <option value={1}>템플릿명</option>
                                                    <option value={2}>템플릿코드</option>
                                                </select>
                                                <input 
                                                    type="text" 
                                                    placeholder='검색할 단어를 입력해주세요.'
                                                    style={{border:'1px solid #d9d9d9', borderRadius:'4px', padding:'6px 25px 6px 7px', width:'70%'}}
                                                    value={keyword}
                                                    onChange={(e) => {setKeyword(e.target.value)}}
                                                ></input>
                                                <button 
                                                    type='button' 
                                                    className='searchBtn'
                                                    style={{marginLeft: '-30px'}}
                                                    onClick={(e) => {
                                                        searchTemplt();
                                                    }}>
                                                        <img src='/images/icon-search-gray.svg' alt="search" className='searchIcon'/>
                                                </button>
                                            </div>
                                            <div className="tt_blank20"></div>
                                            <div className="tt_h2">검색결과 <strong>{totalRecord}</strong> 건</div>
                                            <div style={{height:"580px", overflow:"auto"}}>
                                                <table className="table_mypageArt">
                                                    <colgroup>
                                                        <col width="150px"/>
                                                        <col width="180px"/>
                                                        <col width="180px"/>
                                                        <col width="*"/>
                                                    </colgroup>
                                                    <thead>
                                                        <tr>
                                                            <th style={{position:'sticky', top:-1, zIndex: 1}}>발신프로필</th>
                                                            <th style={{position:'sticky', top:-1, zIndex: 1}}>템플릿코드</th>
                                                            <th style={{position:'sticky', top:-1, zIndex: 1}}>템플릿명</th>
                                                            <th style={{position:'sticky', top:-1, zIndex: 1}}>템플릿내용</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {(() => {
                                                            if(searchResult && Array.isArray(searchResult) && searchResult.length !== 0 && alimProfile){
                                                                return searchResult.map((item, idx) => {
                                                                    return(
                                                                        <tr key={idx}
                                                                            onClick={(e) => {
                                                                                selectRow(idx);
                                                                            }}
                                                                        >
                                                                            <td key={idx+'_1'} style={selectedRow === idx? {background:'#D2E9FE'} : {}}>{alimProfile.filter(ap => {return(ap.senderKey === item.senderKey)})[0].name || ''}</td>
                                                                            <td key={idx+'_2'} style={selectedRow === idx? {background:'#D2E9FE'} : {}}><div>{item.templateData.templtCode || ''}</div></td>
                                                                            <td key={idx+'_3'} style={selectedRow === idx? {background:'#D2E9FE'} : {}}><div>{item.templateData.templtName || ''}</div></td>
                                                                            <td key={idx+'_4'} style={selectedRow === idx? {background:'#D2E9FE'} : {}}><div>{item.templateData.templtContent || ''}</div></td>
                                                                        </tr>
                                                                        )})
                                                            }else{
                                                                return(
                                                                <>
                                                                    <tr>
                                                                        <td colSpan={4}>데이터가 없습니다.</td>
                                                                    </tr>
                                                                </>)
                                                            }
                                                        })()}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        {(() => {
                                           if(searchResult && searchResult.length !== 0){
                                            return (<Pagination data={{page: pageNum, setPage:setPageNum, numPages: totalPages, pageSize: pageSize}}></Pagination>)
                                           }
                                        })()}
                                        </>
                                    )
                                }
                                else{
                                    return <Loading/>
                                }
                            })()}
                            <div className="nt_btn_area_p">
                                <button 
                                    className={selected? "btn_sm6" : "btn_sm6_disabled"}
                                    disabled={!selected}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        loadTemplt();
                                        setModal(false);
                                    }}
                                >불러오기</button>
                                <button className="btn_sm6_white" onClick={(e) => {e.preventDefault(); setModal(false);;}}>닫기</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }else{
        return <></>
    }
}

export default LoadPreviousTemplate;