import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import Loading from '../Util/Loading';

const PaymentResult = () => {
    const [jsonData, setJsonData] = useState({});

    useEffect(() => {
        const jsonDataCookie = Cookies.get('jsonData');
        if(jsonDataCookie){
            const cookieObj = JSON.parse(decodeURIComponent(jsonDataCookie).replaceAll('+', ' '));

            let msg = '';
            if(cookieObj.code === 0 && cookieObj.data && cookieObj.data.resultCode === '0000'){
                if(cookieObj.data.payMethod === 'VirtualAccount'){
                    msg = "(임시) 가상계좌 번호: " + cookieObj.data.virtualAccount;
                }else{
                    msg = "결제가 성공적으로 완료되었습니다.";
                }
            }else{
                if(!cookieObj.data){
                    msg = "결제가 정상적으로 처리되지 않았습니다. 관리자에게 문의해주세요.";
                }else{
                    msg = cookieObj.data.resultMessage;
                }
            }

            window.opener.postMessage(msg, "https://dev.apiplex.net/mypage/payment");
            window.close();
        }
    },[]);

    if(jsonData){
        return (
            <>
            </>
        )
    }else{
        <Loading/>
    }
}

export default PaymentResult