import React, {useState, useEffect} from 'react';
import Loading from '../../Util/Loading';
import { deleteTemplate, getAlimProfile, getATTemplateListPaging, getUserInfor, requestTemplateInspect } from '../../Util/reqApi';
import ServiceConsoleTab06 from './ServiceConsoleTab06';
import ServiceConsoleTab06_modify from './ServiceConsoleTab06_modify';
import ReqInspection from './Component/Modal/ReqInspection';
import Pagination from './Component/Pagination';


const ServiceConsoleTab_templt = () => {
    const [simpleText, setSimpleText] = useState('');
    const [simpleTextModal, setSimpleTextModal] = useState(false);
    const [reasonData, setReasonData] = useState('');
    const [rejectionReasonModal, setRejectionReasonModal] = useState(false);
    const [userId, setUserId] = useState('');
    const [alimProfile, setAlimProfile] = useState(null);
    const [senderKey, setSenderKey] = useState('');

    const [handleCheckList, setHandleCheckList] = useState([]);
    const [callbackFunc, setCallbackFunc] = useState();
    const [inspStatus, setInspStatus] = useState('')
    const [status, setStatus] = useState('')
    const [dormant, setDormant] = useState('')
    const [selectCombo, setSelectCombo] = useState('')
    const [keyword, setKeyword] = useState('');

    const [modal, setModal] = useState(false);
    const [modal02, setModal02] = useState(false);
    const [modifyData, setModifyData] = useState(false);
    const [isChanged, setIsChanged] = useState(false);

    const [templtViewList, setTempltViewList] = useState(null);

    const [reqInspectionModal, setReqInspectionModal] = useState(false);

    const [pageNum, setPageNum] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRecord, setTotalRecord] = useState(0);
    const pageSize = 10;

    const [lastParams, setLastParams] = useState({});

    const initializeInputs = () => {
        if(Object.keys(lastParams).length === 0){
            setSenderKey('');
            setInspStatus('');
            setStatus('');
            setDormant('');
            setSelectCombo('');
            setKeyword('');
        }else{
            setSenderKey(lastParams.senderKeys.split(',').length > 1? '':lastParams.senderKeys.replace(/[\[\]]/g, ''));
            setInspStatus(lastParams.inspStatus);
            setStatus(lastParams.status);
            setDormant(lastParams.dormant);
            setSelectCombo(lastParams.templtCode !== '' ? 'templtCode' : (lastParams.templtName !== '' ? 'templtName' : ''));
            setKeyword(lastParams.templtCode !== '' ? lastParams.templtCode : (lastParams.templtName !== '' ? lastParams.templtName : ''));
        }
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    const loadPageData = () => {
        initializeInputs();

        scrollToTop();
        setTempltViewList(null);

        if(!alimProfile || alimProfile.length === 0) return;

        getATTemplateListPaging({
            ...lastParams,
            userId: userId,
            pageNum: pageNum===0?0:pageNum-1,
            pageSize: pageSize
        })
            .then(result => {
                if(result.code === 2 || result.data.length === 0){
                    setTempltViewList([]);
                    setHandleCheckList([]);
                    setTotalPages(0);
                    setTotalRecord(0);
                    setTotalRecord(0);
                }else{
                    setTotalPages(((result.record + pageSize - 1) / pageSize) | 0);
                    setTotalRecord(result.record);
                    setTempltViewList(result.data);
                    setHandleCheckList(result.data.map((el,idx) =>{
                        return {templtCode : el.templateData.templtCode, checkedProp: false, idx : idx, senderKey: el.senderKey}
                    }))
                }
            }).catch(err => {
            console.error(err);
        });
    }

    useEffect(()=>{
        getUserInfor().then(result => {
            setUserId(result.userId);
            getAlimProfile({userId : result.userId})
            .then(resultProfile => {
                if(resultProfile.code === "200"){
                    setAlimProfile(resultProfile.data.list);
                    let senderKeys = [];
                    for(const alimData of resultProfile.data.list) {
                        senderKeys.push(alimData.senderKey);
                    }
                    setLastParams({
                        senderKeys : senderKeys.toString(),
                    });
                }else{
                    setAlimProfile(resultProfile.message);
                    setSenderKey('');
                    setTempltViewList([]);
                    setHandleCheckList([]);
                }
            });
        })
    },[]);

    useEffect(() => {
        loadPageData();
    },[pageNum, lastParams]);

    useEffect(()=>{
        if(isChanged){
            if(pageNum === 1)   loadPageData();
            else                setPageNum(1);

            setIsChanged(false);
        }
    },[isChanged])

    const searchTemplate = () => {
        //발신프로필이 없을 경우 검색할 수 없으므로 return
        if(!Array.isArray(alimProfile) || alimProfile.length === 0) return;

        let senderKeys = [];
        for(const alimData of alimProfile){
            senderKeys.push(alimData.senderKey);
        }

        setPageNum(1);

        setLastParams({
            senderKeys : senderKey===''?senderKeys.toString():[senderKey].toString(),
            templtCode: selectCombo === 'templtCode' ? keyword : '',
            templtName: selectCombo === 'templtName' ? keyword : '',
            inspStatus: inspStatus,
            status: status,
            block: '',
            dormant: dormant,
        });
    }

    const simpleModalClose = () => {
        setSimpleText('');
        setSimpleTextModal(false);
    }

    const checkCheckBox = () => {
        const result = handleCheckList.filter(el => {
            return el.checkedProp
        })
        return result.length !== 0;
    }

    const reqInspection = () => {
        const result = handleCheckList.filter(el => {
            return el.checkedProp
        });

        checkAllInspectionRequested(result).then((res) => {
            let resMsg = "";

            if(res.resListFail.length !== 0) {
                resMsg += "템플릿 " + res.resListFail.join(", ") + "의 검수 요청에 실패했습니다.\n관리자에게 문의해주세요.\n"
            }else if(res.resListSuccess.length !== 0) {
                resMsg += "템플릿 " + res.resListSuccess.toString() + " 의 검수요청이 성공적으로 완료되었습니다.\n"
            }else{
                //do nothing
            }

            resMsg += "총 "+ res.resListSuccess.length + " 개 템플릿 검수 요청에 성공했습니다."
            alert(resMsg);
            setIsChanged(true);
        })
    }

    const checkAllInspectionRequested = async (result) => {
        let resListSuccess = [];
        let resListFail = [];

        let promises = [];
        let templtCodes = [];

        for(const el of result) {
            promises.push(requestTemplateInspect({senderKeyType:"S", templtCode: el.templtCode, userId: userId, senderKey: el.senderKey}));
            templtCodes.push(el.templtCode);
        }

        return Promise.all(promises)
            .then((result) => {
                for(const [index, res] of result.entries()){
                    if(res.code !== 0 || res.data.code !== '200'){
                        resListFail.push(templtCodes[index]);
                    }else{
                        resListSuccess.push(templtCodes[index]);
                    }
                }
                return {resListSuccess: resListSuccess, resListFail: resListFail};
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const reqDelete = () => {
        const result = handleCheckList.filter(el => {
            return el.checkedProp
        });

        checkAllDeleted(result).then((res) => {
            let resMsg = "";
            if(res.resListNeedChange.length !== 0) {
                resMsg += "템플릿 " + res.resListNeedChange.join(", ") + " 는 삭제 가능한 상태가 아닙니다.\n"
            }else if(res.resListFail.length !== 0) {
                resMsg += "템플릿 " + res.resListFail.join(", ") + " 를 삭제하려면 관리자에게 문의해주세요.\n"
            }else if(res.resListSuccess.length !== 0) {
                resMsg += "템플릿 " + res.resListSuccess.join(", ") + " 는 성공적으로 삭제되었습니다.\n"
            }else{
                //Do nothing
            }

            resMsg += "총 "+ res.resListSuccess.length + " 개 템플릿 삭제에 성공했습니다."
            alert(resMsg);
            setIsChanged(true);

        }).catch(error => {
            alert("오류가 발생했습니다. 관리자에게 문의해주세요. (",error,")");
        });
    }

    const checkAllDeleted = async (result) => {
        let resListSuccess = [];
        let resListFail = [];
        let resListNeedChange = [];

        let promises = [];
        let templateCodes = [];

        for(const el of result){
            promises.push(deleteTemplate({senderKeyType:"S", templtCode: el.templtCode, userId: userId, senderKey: el.senderKey}));
            templateCodes.push(el.templtCode);
        }

        return Promise.all(promises)
            .then((result) => {
                for(const [index, res] of result.entries()) {
                    if(res.data.code !== '200'){
                        if(res.data.code === '509'){
                            // 삭제할수 없는 상태의 템플릿(승인 또는 검수요청 상태)
                            resListNeedChange.push(templateCodes[index]);
                        } else {
                            // 기타 에러
                            resListFail.push(templateCodes[index]);
                        }
                    }else{
                        resListSuccess.push(templateCodes[index]);
                    }
                }
                return {resListSuccess: resListSuccess, resListFail: resListFail, resListNeedChange: resListNeedChange}
            });
    }

    const reqDownload = () => {
        let tempList = [];
        const result = handleCheckList.filter(el => {
            return el.checkedProp
        })
        result.forEach(el => {
            tempList.push(el.templtCode);
        })
        
        window.open(`/mnt/nas/export/templt?userId=${userId}&senderKey=${senderKey}&templtCodeList=${tempList.join(',')}`)
    }

    if(alimProfile && templtViewList){
        return(
            <div id="cons-2" className="tab-content current">
                <div className="tt_h2">알림톡 템플릿 관리</div>
                <div className="option_bar5">
                    <div className="t_date_option_arT" style={{display:"flex"}}>
                        <span className="op_box" style={{marginRight:'10px', display:"inline-block"}}>
                            <span>발신 프로필</span>
                            <select style={{maxWidth:"100px"}}
                                onChange={(e)=>{
                                    setSenderKey(e.target.value || "")
                                }}
                                value={senderKey || ""}
                            >
                                <option value={""}>전체</option>
                                {(()=>{
                                    if(Array.isArray(alimProfile)){
                                        return alimProfile.map((el,idx)=>{
                                            return <option key={idx} value={el.senderKey}>{el.name}</option>
                                        })
                                    }
                                })()}
                            </select>
                        </span>
                        <span className="op_box" style={{marginRight:'10px', display:"inline-block"}}>
                            <span>검수상태</span>
                            <select
                                style={{maxWidth:"80px"}}
                                onChange={(e)=>{setInspStatus(e.target.value)}}
                                value={inspStatus || ""}
                            >
                                <option value="">전체</option>
                                <option value="REG">등록</option>
                                <option value="REQ">심사요청</option>
                                <option value="APR">승인</option>
                                <option value="REJ">반려</option>
                            </select>
                        </span>
                        <span className="op_box" style={{marginRight:'10px', display:"inline-block"}}>
                            <span>템플릿상태</span>
                            <select
                                style={{maxWidth:"80px"}}
                                value={status || ""}
                                onChange={(e)=>{
                                    setStatus(e.target.value);
                                }}
                            >
                                <option value="">전체</option>
                                <option value="A">정상</option>
                                <option value="S">중지</option>
                                <option value="R">대기(발송전)</option>
                            </select>
                        </span>
                        <span className="op_box" style={{marginRight:'10px', display:"inline-block"}}>
                            <span>휴면상태</span>
                            <select
                                style={{maxWidth:"100px"}}
                                value={dormant || ""}
                                onChange={(e)=>{
                                    setDormant(e.target.value);
                                }}
                            >
                                <option value="">전체</option>
                                <option value={true}>휴면</option>
                                <option value={false}>휴면해제</option>
                            </select>
                        </span>
                        <span className="op_box" style={{marginRight:'10px', display:"inline-block", marginLeft:'auto'}}>
                            <select
                                style={{maxWidth:"100px"}}
                                value={selectCombo}
                                onChange={(e)=>{
                                    setSelectCombo(e.target.value);
                                }}
                            >
                                <option value="">검색옵션</option>
                                <option value="templtCode">템플릿코드</option>
                                <option value="templtName">템플릿명</option>
                            </select>
                            <input
                                type="text"
                                style={{maxWidth:"200px",marginRight:'10px'}}
                                value={keyword}
                                onChange={(e) => {setKeyword(e.target.value)}}
                            />
                            <button className="btn_sm6" style={{height:'28px', padding:'0 10px'}} onClick={()=>{
                                searchTemplate();
                            }}>
                                조회하기
                            </button>
                        </span>
                    </div>
                </div>
                <div className="option_bar" style={{display : "flex"}}>
                    <button
                        className="btn_sm4"
                        style={{marginRight: "10px", border: "1px solid #0096c6", background:"#0096c6", color:"#fff"}}
                        onClick={()=>{
                            if(checkCheckBox()){
                                setCallbackFunc(()=>reqInspection)
                                setSimpleText('선택한 템플릿을 검수요청 하시겠습니까?')
                                setSimpleTextModal(true)
                            }else{
                                setCallbackFunc(()=>simpleModalClose)
                                setSimpleText('검수 요청할 템플릿을 선택해주세요.')
                                setSimpleTextModal(true)
                            }                            
                        }}
                    >
                        선택 템플릿 검수요청
                    </button>
                    <button
                        className="btn_sm4"
                        style={{marginRight: "10px", border: "1px solid #d2001a", background:"#d2001a", color:"#fff"}}
                        onClick={()=>{
                            if(checkCheckBox()){
                                setCallbackFunc(()=>reqDelete)
                                setSimpleText('선택한 템플릿을 삭제 하시겠습니까?')
                                setSimpleTextModal(true)
                            }else{
                                setCallbackFunc(()=>simpleModalClose)
                                setSimpleText('삭제할 템플릿을 선택해주세요.')
                                setSimpleTextModal(true)
                            }    
                        }}
                    >
                        선택 템플릿 삭제
                    </button>
                    {/*<button*/}
                    {/*    className="btn_sm4"*/}
                    {/*    style={{marginRight: "10px"}}*/}
                    {/*    onClick={()=>{*/}
                    {/*        if(checkCheckBox()){*/}
                    {/*            setCallbackFunc(()=>reqDownload)*/}
                    {/*            setSimpleText('선택한 템플릿을 다운로드 하시겠습니까?')*/}
                    {/*            setSimpleTextModal(true)*/}
                    {/*        }else{*/}
                    {/*            setCallbackFunc(()=>simpleModalClose)*/}
                    {/*            setSimpleText('다운로드할 템플릿을 선택해주세요.')*/}
                    {/*            setSimpleTextModal(true)*/}
                    {/*        }   */}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    선택 템플릿 다운로드*/}
                    {/*</button>*/}
                    <button className="btn_sm6"
                            style={{marginLeft:"auto"}} 
                            onClick={(e)=>{e.preventDefault();setModal(true)}}>
                        신규 등록
                    </button>
                </div>
                {(()=>{
                    if(templtViewList){
                        return(
                            <>
                                <div className="tt_script" style={{marginLeft:"5px", marginBottom:"5px"}}>검색 결과 <strong>{totalRecord}</strong> 개</div>
                                <table className="table_mypageArt">
                                    <colgroup>
                                        <col width="40px" />
                                        <col width="140px" />
                                        <col width="180px" />
                                        <col width="140px" />
                                        <col width="*" />
                                        <col width="80px" />
                                        <col width="80px" />
                                        <col width="80px" />
                                        <col width="100px" />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>
                                                <input
                                                    type="checkbox"
                                                    checked={                                        
                                                        handleCheckList.length !== 0 && (!handleCheckList.find(el => el.checkedProp === false))
                                                    }
                                                    onChange={(e)=>{
                                                        setHandleCheckList(handleCheckList.map(el => {
                                                            el.checkedProp = e.target.checked;
                                                            return el;
                                                        }))
                                                    }}
                                                />
                                            </th>
                                            <th>발신프로필</th>
                                            <th>템플릿코드</th>
                                            <th>템플릿명</th>
                                            <th>메시지내용</th>
                                            <th>버튼정보</th>
                                            <th>검수상태</th>
                                            <th>템플릿상태</th>
                                            <th>상세</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(()=>{
                                            if(Array.isArray(templtViewList) && templtViewList.length !== 0 && alimProfile){
                                                return templtViewList.map((templateData,idx) => {
                                                    return <TemplateRow 
                                                                key={idx} 
                                                                idx={idx}
                                                                senderKeyName={alimProfile.filter(item => {return(item.senderKey === templateData.senderKey)})[0].name}
                                                                templateDetail={templateData}
                                                                handleCheckList={handleCheckList} 
                                                                setHandleCheckList={setHandleCheckList}
                                                                modal02={modal02} 
                                                                setModal02={setModal02} 
                                                                setModifyData={setModifyData} 
                                                                setRejModal={setRejectionReasonModal} 
                                                                setReasonData={setReasonData}
                                                                userId={userId}
                                                                senderKey={templateData.senderKey}
                                                                />
                                                })
                                            }else{
                                                return (<tr><td colSpan={9}>데이터가 없습니다.</td></tr>)
                                            }
                                        })()}
                                    </tbody>
                                </table>
                                {(() => {if(templtViewList.length !== 0)
                                    {return (
                                        <>
                                            <Pagination data={{ page: pageNum, setPage:setPageNum, numPages: totalPages, pageSize: pageSize}}></Pagination>
                                            <div className="tt_blank10"></div>
                                        </>
                                    )}
                                })()}
                            </>
                        )
                    }else return <Loading />
                })()}
                {simpleTextModal ? <SimpleModal simpleTextModal={simpleTextModal} setSimpleTextModal={setSimpleTextModal} callbackFunc={callbackFunc}>{simpleText}</SimpleModal> : null}
                {rejectionReasonModal ? <RejectionReasonModal rejectionReasonModal={rejectionReasonModal} setRejectionReasonModal={setRejectionReasonModal} reasonData={reasonData} setReqInspectionModal={setReqInspectionModal} setModal02={setModal02} setModifyData={setModifyData} modifyData={modifyData} /> : null}
                {modal ? <ServiceConsoleTab06 modal={modal} setModal={setModal} setIsChanged={setIsChanged} userId={userId} alimProfile={alimProfile}/> : null}
                {modal02 ? <ServiceConsoleTab06_modify modal={modal02} setModal={setModal02} modifyData={modifyData} setIsChanged={setIsChanged} userId={userId} setReqInspectionModal={setReqInspectionModal}/> : null}
                {reqInspectionModal ? <ReqInspection modal={reqInspectionModal} setModal={setReqInspectionModal} setIsChanged={setIsChanged} inspectionData={{...modifyData, userId:modifyData.userId, senderKey: modifyData.senderKey}} setModal02={setModal02}></ReqInspection> : null}
            </div>
        )
    }else return <Loading />
}

const SimpleModal = (props) => {
    const {children, simpleTextModal, setSimpleTextModal, callbackFunc} = props;
    return(
        <div className="pop_set_c" style={simpleTextModal ? {display:'block'} : {display:'none'}}>
            <div className="pop_section">
                <div className="parea">
                    <div className="page">
                        <div className="ppheader">
                            <div className="htit">알림</div>
                            <div className="rclose" onClick={()=>{setSimpleTextModal(false)}}><img src={'/images/icon_pop_close.png'} alt="닫기"/></div>
                        </div>
                        <div>
                            <div className="s_u_p_tit">{children}</div>
                            <div className="s_u_p_btn">
                                <button
                                    className="login_2"
                                    onClick={()=>{
                                        callbackFunc();
                                        setSimpleTextModal(false);
                                    }}
                                >
                                    확인
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const RejectionReasonModal = (props) => {
    const {rejectionReasonModal, setRejectionReasonModal, reasonData, setModal02, setModifyData, modifyData} = props;
    return(
        <div className="pop_set_p" style={rejectionReasonModal ? {display:'block'} : {display:'none'}}>
            <div className="pop_section">
                <div className="parea">
                    <div className="page" style={{width:'900px'}}>
                        <div className="ppheader">
                            <div className="htit">템플릿 반려사유 확인</div>
                            <div className="rclose" onClick={()=>{setRejectionReasonModal(false)}}><img src={'/images/icon_pop_close.png'} alt="닫기"/></div>
                        </div>
                        <div>
                            <table className="tt_tableA">
                              <colgroup>
                                <col width="100px" style={{textAlign:"center"}}/>
                                <col width="80px" style={{textAlign:"center"}}/>
                                <col width="*"/>
                              </colgroup>
                              <thead>
                                <tr>
                                  <th>생성일</th>
                                  <th>작성자</th>
                                  <th>내용</th>
                                </tr>
                              </thead>
                              <tbody>
                                {reasonData.map((item, index) => (
                                  <tr key={index}>
                                    <td>{item.createdAt}</td>
                                    <td>{item.userName}</td>
                                    <td><textarea className="textarea_disabled" disabled={true} value={item.content}>{item.content}</textarea></td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <div className="button_area_center">
                                <button className="btn_sm6_yellow"
                                        style={{marginRight: '10px'}}
                                        onClick={() => {
                                            setModifyData(modifyData);
                                            setModal02(true);
                                        }}>템플릿 수정하기
                                </button>
                                <button
                                    className="btn_sm6_white"
                                    onClick={() => {
                                        setRejectionReasonModal(false);
                                    }}
                                >
                                    닫기
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const TemplateRow = (props) => {
    const {
        templateDetail,
        handleCheckList,
        setHandleCheckList,
        idx,
        setModal02,
        setModifyData,
        setRejModal,
        setReasonData,
        userId,
        senderKey,
        senderKeyName
    } = props;
    const inspStatusText = {REG:"등록", REQ:"심사요청", APR:"승인", REJ: "반려"}
    const statusText = {S:"중단", A:"정상", R:"대기(발송전)"}

    if(templateDetail && handleCheckList && handleCheckList.length !== 0){
        const data = templateDetail.templateData;
        const profileName = senderKeyName || '';

        return(
            <tr>
                <td>
                    <input
                        type="checkbox"
                        checked={handleCheckList && handleCheckList[idx] && handleCheckList[idx].checkedProp}
                        onChange={(e)=>{
                            setHandleCheckList(handleCheckList.map((el, _idx)=>{
                                if(idx === _idx){el.checkedProp = e.target.checked}
                                return el;
                            }))
                        }}
                    />
                </td>
                <td>{profileName}</td>
                <td>{data.templtCode}</td>
                <td>{data.templtName}</td>
                <td>{data.templtContent}</td>
                <td>{data.buttons ? data.buttons.length : 0}개</td>
                <td>
                {inspStatusText[data.inspStatus]}
                {
                    inspStatusText[data.inspStatus] === "반려" ? (<button className="btn_sm9" onClick={() => {setReasonData(data.comments); setRejModal(true); setModifyData({...data, selectedATProfile: profileName, userId: userId, senderKey: senderKey});}}>사유확인</button>) : ""
                }
                </td>
                <td>{statusText[data.status]}</td>
                <td>
                    <button
                        className="btn_sm7"
                        onClick={()=>{
                            setModifyData({...data, selectedATProfile: profileName, userId: userId, senderKey: senderKey})
                            setModal02(true);
                        }}
                    >상세조회</button>
                </td>
            </tr>
        )
    }else return(<></>);
}

export default ServiceConsoleTab_templt;